const videos = [
  {
    url: "/app/conversor",
    url_iframe:
      "https://easydrcontabilidade-my.sharepoint.com/personal/gestao_easydr_com_br/_layouts/15/embed.aspx?UniqueId=35cf3b77-9119-4d58-b8e7-df0328fbf921&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=OneUpFileViewer&referrerScenario=EmbedDialog.Create",
    description:
      "Neste tutorial abrangente, você será guiado passo a passo sobre como criar um arquivo no formato OFX, que é amplamente utilizado para a troca de dados financeiros, e também aprenderá o processo de importação desse arquivo no domínio específico, tornando-o mais acessível e útil para suas necessidades financeiras.",
  }
  ,{
    url: "/app/colab-wallet",
    url_iframe:
      "https://easydrcontabilidade-my.sharepoint.com/personal/gestao_easydr_com_br/_layouts/15/embed.aspx?UniqueId=28117f8d-802a-4dc4-8803-44c60b9f843d&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=StreamWebApp&referrerScenario=EmbedDialog.Create",
    description:
      "Neste tutorial, você será guiado passo a passo sobre como criar um arquivo de relatório de faturamento em formato xlsx.",
  }
  ,{
    url: "/app/tools/guias-ecac-colaborador",
    url_iframe:
      "https://easydrcontabilidade-my.sharepoint.com/personal/gestao_easydr_com_br/_layouts/15/embed.aspx?UniqueId=3769e0b9-a391-4d10-aa3f-ff00c89b8bf6&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=OneUpFileViewer&referrerScenario=EmbedDialog.Create",
    description:
      "Neste tutorial, você será guiado passo a passo sobre como gerar um arquivo zip com as guias no formato pdf.",
  }
];

function getVideo(url) {
  const myVideo = videos.filter((video) => video.url === url);
  if (myVideo.length === 0) return false;
  return myVideo[0];
}

export { getVideo };

import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`

:root {
  --cc-bg: rgb(254 255 244 / 30%);
  --cc-text-title: #025dfd;
  --cc-text: #0b1e3e;
  --cc-inactive-color: #97989b;
  --cc-btn-primary-bg: #03bdca;
  --cc-btn-primary-text: #fff;
  --cc-btn-primary-hover-bg: #00e7f9;
  --cc-btn-secondary-bg: #025dfd;
  --cc-btn-secondary-text: var(--cc-text);
  --cc-btn-secondary-hover-bg: #3e454a;
  --cc-toggle-bg-off: #667481;
  --cc-toggle-bg-on: var(--cc-btn-primary-bg);
  --cc-toggle-bg-readonly: #8a8a8a;
  --cc-toggle-knob-bg: var(--cc-cookie-category-block-bg);
  --cc-toggle-knob-icon-color: var(--cc-text);
  --cc-cookie-category-block-bg: #23272a;
  --cc-cookie-category-block-bg-hover: #2b3035;
  --cc-section-border: #292d31;
  --cc-cookie-table-border: #2b3035;
  --cc-webkit-scrollbar-bg: #667481;
  --cc-webkit-scrollbar-bg-hover: #9199a0;
}

@media (max-width: 1080px) {
  html {
    font-size: 93.75%;
  }
}

@media (max-width: 999px) {
  html {
    font-size: 87.5%;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-wrap: anywhere;
}

body {
  background: var(--cc-bg);
  color: var(--cc-text);
  overflow-x: hidden;
  min-height: 100vh;
}

body::-webkit-scrollbar {
  width: 5px;
}

body::-webkit-scrollbar-thumb {
  background: #0070FF;
  border-radius: 10px;
}

body::-webkit-scrollbar-track {
  background: transparent;
}

body,
input,
textarea,
select,
button {
  font-weight: 400;
  font-size: 1rem;
  font-family: "fieldwork-hum", sans-serif;
}

button {
  cursor: pointer;
}

a {
  color: inherit;
  text-decoration: none;
}

.react-cookie-law-dialog {
  position: bottom !important;
}
ul {
    padding-left: 0px;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 85px;
  height: 44px;
  box-shadow: rgb(0 0 0 / 45%) -3px 4px 36px 0px;
  border-radius: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 38px;
  width: 38px;
  left: 17px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #74cf93;
}

input:focus + .slider {
  box-shadow: 0 0 1px #74cf93;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* hide disabled buttons */
.rec.rec-arrow:disabled {
  visibility: hidden;
}


/* square buttons */
.rec.rec-arrow {
  display: flex;
  justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    background: rgb(237, 243, 254);
    border: none;
    border-radius: 0.5rem;
    color: rgb(2, 93, 253);
    font-weight: 400;
    text-align: center;
    outline: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out 0s;
    box-shadow: rgb(0 0 0 / 20%) 0px 0px 20px 6px;
    background-color: transparent;
}
/* round buttons on hover */
.rec.rec-arrow:hover, .rec.rec-arrow:focus:enabled {
    border-radius: 50%;
    background-color: #025dfd;
}

.rec.rec-item-wrapper{
  height: 100%;
}

button.rec-dot_active{
  background-color: #025dfd;
  box-shadow: #025dfd73 0px 0px 1px 3px;
}

button.rec-dot:hover, button.rec-dot:active, button.rec-dot:focus  {
  box-shadow: rgb(2 93 253 / 11%) 0px 0px 1px 3px;
}

#pdfviewerprivacy{
  width: 100%;
  min-height: calc(100vh - 100px);
}

.form-application{
}

.sidebar-lp {
  position: fixed;
  top: 0;
  right: -100%;
  height: 100vh;
  width: 250px !important;
  z-index: 99999;
  background-color: rgba(255,255,255,0.3);
  backdrop-filter: blur(25px);
  box-shadow: -10px 0 10px rgba(0,0,0,0.1);
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start !important;
  transition: 0.5s ease-out;
  li{
    width: 100%;
  }
  a {
    color: #025dfd;
    font-size: 1rem;
    line-height: 2rem;
    width: 100%;
  }

  button {
    height: 100%;
    width: 100%;
    padding: 0 25px;
    text-decoration: none;
    display: flex;
    align-items: center;
    color: black;
    color: #025dfd;
    font-size: 1rem;
    line-height: 2rem;
    border: none;
    border-radius: 0.5rem;
    transition: 0.2s ease-in-out;

    svg {
      color: #025dfd;
    }

    &:hover {
      background-color: #025dfd;
      color: #fff;
      svg {
          color: #fff;
      }
    }
  }
}

.sidebar-lp.visible {
  right: 0;
}

.menu-button {
  display: none;
  background: transparent;
}
@media(max-width: 900px) {
    .hideOnMobile {
      display: none;
    }
        .menu-button {
      display: flex;

    }
  }


@media(max-width: 500px) {
    .sidebar-lp {
      width: 100% !important;
    }
    .menu-desktop {
      padding: 1em !important;
    }
  }
`;

import React from "react";

const CadastroGrid = ({ data }) => {
  if (!data) {
    return null;
  }
  return (
    <div className="row">
      <div
        className="col-md-7"
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <span className="span-data">
          <strong>Email da Empresa:</strong> {data?.email_emp}
        </span>
        <span className="span-data">
          <strong>Endereço no Legado da Empresa:</strong> {data?.end_leg_emp}
        </span>
        <span className="span-data">
          <strong>Endereço da Empresa:</strong> {data?.ende_emp}
        </span>
        <span className="span-data">
          <strong>Estado da Empresa:</strong> {data?.esta_emp}
        </span>
        <span className="span-data">
          <strong>Telefone da Empresa:</strong> {data?.fone_emp}
        </span>
        <span className="span-data">
          <strong>Telefone no Legado da Empresa:</strong> {data?.fone_leg_emp}
        </span>
        <span className="span-data">
          <strong>GUID Contábil:</strong> {data?.guid_contabil}
        </span>
        <span className="span-data">
          <strong>Município no Legado da Empresa:</strong> {data?.mun_leg_emp}
        </span>
        <span className="span-data">
          <strong>Nome da Empresa:</strong> {data?.nome_emp}
        </span>
        <span className="span-data">
          <strong>Número da Empresa:</strong> {data?.nume_emp}
        </span>
        <span className="span-data">
          <strong>Número no Legado da Empresa:</strong> {data?.nume_leg_emp}
        </span>
        <span className="span-data">
          <strong>Observação Pessoal:</strong> {data?.obs_pessoal}
        </span>
        <span className="span-data">
          <strong>Responsável Legal da Empresa:</strong> {data?.rleg_emp}
        </span>
        <span className="span-data">
          <strong>Status da Empresa:</strong> {data?.stat_emp}
        </span>
        <span className="span-data">
          <strong>Tipo de Endereço da Empresa:</strong> {data?.tipo_end_emp}
        </span>
        <span className="span-data">
          <strong>Tipo de Pessoa da Empresa:</strong> {data?.tipoi_emp}
        </span>
        <span className="span-data">
          <strong>UF no Legado da Empresa:</strong> {data?.uf_leg_emp}
        </span>
      </div>
      <div
        className="col-md-5"
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <span className="span-data">
          <strong>Bairro da Empresa:</strong> {data?.bair_emp}
        </span>
        <span className="span-data">
          <strong>Bairro do Legado da Empresa:</strong> {data?.bair_leg_emp}
        </span>
        <span className="span-data">
          <strong>Cargo no Legado da Empresa:</strong> {data?.cargo_leg_emp}
        </span>
        <span className="span-data">
          <strong>CEP no Legado da Empresa:</strong> {data?.cep_leg_emp}
        </span>
        <span className="span-data">
          <strong>CEP da Empresa:</strong> {data?.cepe_emp}
        </span>
        <span className="span-data">
          <strong>CGCE da Empresa:</strong> {data?.cgce_emp}
        </span>
        <span className="span-data">
          <strong>Código da Empresa:</strong> {data?.codi_emp}
        </span>
        <span className="span-data">
          <strong>Código do Município:</strong> {data?.codigo_municipio}
        </span>
        <span className="span-data">
          <strong>Código do País:</strong> {data?.codigo_pais}
        </span>
        <span className="span-data">
          <strong>Complemento da Empresa:</strong> {data?.comp_emp}
        </span>
        <span className="span-data">
          <strong>Complemento no Legado da Empresa:</strong> {data?.comp_leg_emp}
        </span>
        <span className="span-data">
          <strong>CPF no Legado da Empresa:</strong> {data?.cpf_leg_emp}
        </span>
        <span className="span-data">
          <strong>Data de Cadastro da Empresa:</strong> {data?.dcad_emp}
        </span>
        <span className="span-data">
          <strong>DDD da Empresa:</strong> {data?.dddf_emp}
        </span>
        <span className="span-data">
          <strong>DDD no Legado da Empresa:</strong> {data?.dddf_leg_emp}
        </span>
        <span className="span-data">
          <strong>Data de Início da Empresa:</strong> {data?.dina_emp}
        </span>
      </div>
    </div>
  );
};

export default CadastroGrid;

import React from "react";
import Carousel from "react-elastic-carousel";
import CardSolution from "./CardSolution";
import data from "../../../data/datajobs";
import { Container, Content, Header } from "./styles";

export default function Vagas() {
  const renderCards = () =>
    data.map((item) => {
      if (item.active) {
        return <CardSolution
        key={item.id}
        title={item.title}
        subTitle={item.subTitle}
        job={item.job}
        active={item.active}
      />
      }
      return null;
    });
  const breakpoints = [
    {
      width: 1,
      itemsToShow: 1,
      showArrows: false,
      pagination: true,
      itemPadding: [20, 10],
    },
    {
      width: 550,
      itemsToShow: 2,
      itemsToScroll: 2,
      showArrows: true,
      pagination: false,
      itemPadding: [20, 10],
    },
    { width: 850, itemsToShow: 3, itemPadding: [30, 20] },
  ];
  return (
    <Container id="trabalhe-conosco">
      <Content>
        <Header>
          <h1>Trabalhe Conosco</h1>
        </Header>
        <p>
          A EasyDr. é uma empresa inovadora no setor de contabilidade médica,
          presente em mais de 300 cidades pelo Brasil. Nosso compromisso é
          proporcionar segurança, redução de custos e mais tempo para que nossos
          clientes possam se dedicar plenamente às suas carreiras médicas.
        </p>
        <Carousel itemsToShow={3} breakPoints={breakpoints} pagination={false}>
          {renderCards()}
        </Carousel>
      </Content>
    </Container>
  );
}

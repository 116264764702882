import React from 'react';
import DataTable from 'react-data-table-component';
import { useHistory } from 'react-router-dom';



const Usuarios = () => {
  const users = [
    {
      id: 1,
      nome: "Joao",
      email: "santanadeveloper@outlook.com",
      idade: 26,
      cargo: "Programador",
      status: "Ativo",
    },
    {
      id: 2,
      nome: "Miquéias",
      email: "miqueiaskelvyn@gmail.com",
      idade: 16,
      cargo: "Estagiário Auxiliar de T.I",
      status: "Ativo",
    },
  ];

  const history = useHistory();

  function editUser(row) {
    if (row && row.id) {
      history.push({
        pathname: `/app/usuarios/editar/${row.id}`,
        state: { user: row }
      });

    } else {
      console.log("O objeto 'row' ou a propriedade 'id' não está definido");
    }
  }

  const columns = [
    {
      name: "Nome",
      selector: (row) => row.nome,
      sortable: true,
    },
    {
      name: "E-Mail",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Idade",
      selector: (row) => row.idade,
      sortable: true,
    },
    {
      name: "Cargo",
      selector: (row) => row.cargo,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: "Editar",
      selector: (row) => (
        <button
          type="button"
          onClick={() => editUser(row)}
          className="btn btn-primary btn-sm"
        >
          Editar Usuário
        </button>
      ),

      sortable: true,
    },
  ];

  return (
    <div>
      <h5>Usuários</h5>
      <br />
       <DataTable
        columns={columns}
        data={users}
        fixedHeader
        title="Usuários em nossa base de dados"
        pagination
        selectableRows
      />
    </div>
  );
};

export default Usuarios;

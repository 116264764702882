import Pjtinha from "../assets/images/servicos/pjotinha.webp";
import ClinicaConsultorio from "../assets/images/servicos/clinicas_consultorio.webp";
import Declaracao from "../assets/images/servicos/declaracao_irpf.webp";
import Seguro from "../assets/images/servicos/seguros.webp";
import INSS from "../assets/images/servicos/inss.webp";

const data = [
  {
    id: 1,
    price: "A partir de R$398",
    image: Pjtinha,
    category: "Contabilidade",
    title: "Contabilidade para Plantões",
    subTitle: "Plano piloto automático e plano básico",
    badge: "Novo",
    buttonType: "link",
    link: "https://marketing.easydr.com.br/fale-com-a-easydr",
  },
  {
    id: 2,
    price: "A partir de R$760",
    image: ClinicaConsultorio,
    category: "Contabilidade",
    title: "Clínicas e Consultórios",
    subTitle: "Assessoria contábil especializada e completa",
    buttonType: "link",
    link: "https://marketing.easydr.com.br/contabilidade-medica-para-clinica-e-consultorio",
  },
  {
    id: 3,
    price: "A partir de R$300",
    image: Declaracao,
    category: "Contabilidade",
    title: "Declaração do Imposto de Renda/IRPF",
    subTitle: "Declaração básica, completa e declaração de investimento",
    buttonType: "link",
    link: "https://marketing.easydr.com.br/fale-com-a-easydr",
  },
  {
    id: 4,
    price: "A Consultar",
    image: INSS,
    category: "INSS",
    title: "Recuperação de INSS ",
    subTitle: "Recupere valores contribuídos a mais para o INSS.",
    badge: "Novo",
    buttonType: "link",
    link: "https://marketing.easydr.com.br/recuperacao-de-inss-com-easydr",
  },
  {
    id: 5,
    price: "A Consultar",
    image: Seguro,
    category: "Seguros",
    title: "Seguro de Vida Resgatável",
    subTitle: "Tranquilidade para você e sua família",
    badge: "Novo",
    buttonType: "link",
    link: "https://marketing.easydr.com.br/fale-com-a-easydr",
  },
];

export default data;

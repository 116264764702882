import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  max-width: 1450px;
  height: auto;
  padding: 0 2rem;
  margin: 0 auto;

  .mg-top {
    margin-top: 3rem;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1em;
  @media (max-width: 1100px) {
    display: block;
  }

  p {
    max-width: 1030px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  span {
    color: #86888d;
  }

  h1 {
    margin: 3rem 0;
    font-size: 2.5rem;
  }

  p {
    opacity: 0.7;
    font-weight: 300;
  }
`;

export const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  justify-items: stretch;
  align-items: stretch;
`;

export const Card = styled.div`
  width: 100%;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 13px 5px;
  text-align: center;
  backdrop-filter: blur(4.5px);
  -webkit-backdrop-filter: blur(4.5px);
  color: #fff;
  font-size: 21px;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;

  font-family: "fieldwork-hum", sans-serif;
  transition: 0.2s all;

  &:hover {
    transform: scale(1.02);
  }
`;

export const Description = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 136px;
  padding: 20px;
`;

export const Name = styled.h1`
  color: black;
  font-size: 1.2em;
  font-weight: 500;
`;

export const Services = styled.p`
  color: black;
  font-size: 1.1rem;
`;

export const Button = styled.button`
  padding: 5px 0px;
  background-color: rgb(2, 93, 253);
  color: rgb(243, 243, 243);
  border: 1px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  border-radius: 6px;
  transition: all 0.2s;
  &:hover {
    color: rgb(243, 243, 243);
    background-color: #25d366;
  }
`;

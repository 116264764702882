import nexus from "../services/nexus";

async function getCnpj(cnpj) {

    try {
        const { data } = await nexus.get("/tools/cnpj/" + cnpj);
        return data
    }
    catch (e) {

        return {
            faturamento: [],
            cadastro: null,
            receita: null
        }
    }

}

export default {
    getCnpj,
};

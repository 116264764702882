/* eslint-disable no-unused-vars */
import React from "react";
import { BsWhatsapp, BsLink45Deg } from "react-icons/bs";
import {
  Card,
  Price,
  Category,
  Logo,
  Description,
  Name,
  Services,
  Link,
} from "./styles";

const CardSolution = ({
  price,
  image,
  category,
  title,
  subTitle,
  badge,
  link,
  buttonType,
}) => (
  <Card>
    <div className="ribbon">
      <span>{badge}</span>
    </div>
    <Price>{price}</Price>
    <Logo src={image} alt={title} />
    <Category className="badge rounded-pill bg-primary mt-2 mb-2">
      {category}
    </Category>
    <Description>
      <Name className="display-6">{title}</Name>
      <Services>{subTitle}</Services>
      <Link href={link} target="_blank">
        {buttonType === "whatsapp" ? (
          <>
            <BsWhatsapp />
            <span>Fale conosco</span>
          </>
        ) : (
          <>
            <BsLink45Deg />
            <span>Acessar Site</span>
          </>
        )}
      </Link>
    </Description>
  </Card>
);

export default CardSolution;

import { useEffect, useState } from "react";
import { Container, ListGroup } from "react-bootstrap";
import { FiDownload } from "react-icons/fi";
import nexus2 from "../../../services/nexus2";

function ListNfes() {
  const [results, setResults] = useState([]);

  const start = async () => {
    const res = await nexus2.get("/nfe/list");
    setResults(res?.data?.arquivos ?? []);
  };

  const download = async (arquivoname) => {
    try {
      const response = await nexus2.get(`/nfe/download/${arquivoname}`, {
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;

      // Extrair o nome do arquivo do header da resposta
      const contentDisposition = response.headers["content-disposition"];
      const fileName =
        contentDisposition.split("filename=")[1] || "arquivo_download";
      link.setAttribute("download", fileName.replaceAll('"', ""));

      // Adicionar o link ao DOM e realizar o clique para iniciar o download
      document.body.appendChild(link);
      link.click();

      // Remover o link do DOM após o download
      document.body.removeChild(link);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    start();
    setTimeout(() => {
      start();
    }, 15000);
  }, []);

  return (
    <Container>
      <div className="mt-4 mb-4">
        <h5>Notas Emitidas:</h5>
      </div>
      {results.length > 0 ? <h6>Resultados:</h6> : null}
      <ListGroup>
        {results.map((e) => (
          <ListGroup.Item>
            <a
              onClick={() => download(e)}
              style={{ flexDirection: "row", cursor: "pointer" }}
            >
              <FiDownload style={{ marginRight: 5 }} /> {e}
            </a>
          </ListGroup.Item>
        ))}

        {results.length === 0 ? (
          <span style={{ marginTop: 20 }}>Nenhum resultado encontrado.</span>
        ) : null}
      </ListGroup>
    </Container>
  );
}

export default ListNfes;

import styled from "styled-components";
import imgBg from "../../assets/images/site/bg-site.webp";

export const NavbarContainer = styled.header`
  width: 100%;
  height: 100px;
  padding: 0 2rem;
  background: #f3f3f3;
  z-index: 100;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
`;

export const NavbarContent = styled.div`
  width: 100%;
  max-width: 1280px;
  height: 100%;
  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Menu = styled.nav`
  display: flex;
  align-items: center;

  a {
    margin-left: 10px;
    color: #025dfd;
    font-size: 1rem;
    line-height: 2rem;
    text-align: center;
    align-items: center;
  }

  a:nth-of-type(1) {
    margin-right: 0.5em;
  }
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  padding: 0.7em 1em;
  background: #025dfd;
  border: none;
  border-radius: 0.5rem;
  color: #edf3fe;
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
  outline: none;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  box-shadow: -6px -6px 14px rgba(255, 255, 255, 0.7),
    -6px -6px 10px rgba(255, 255, 255, 0.5),
    6px 6px 8px rgba(255, 255, 255, 0.075), 6px 6px 10px rgba(0, 0, 0, 0.15);

  svg:nth-of-type(1) {
    margin-right: 0.5em;
  }

  &:hover {
    box-shadow: -2px -2px 6px rgba(255, 255, 255, 0.6),
      -2px -2px 4px rgba(255, 255, 255, 0.4),
      2px 2px 2px rgba(255, 255, 255, 0.05), 2px 2px 4px rgba(0, 0, 0, 0.1);
  }

  &:active {
    box-shadow: inset -2px -2px 6px rgba(255, 255, 255, 0.7),
      inset -2px -2px 4px rgba(255, 255, 255, 0.5),
      inset 2px 2px 2px rgba(255, 255, 255, 0.075),
      inset 2px 2px 4px rgba(0, 0, 0, 0.15);
  }
`;

export const Hero = styled.section`
  margin: 0 auto;
  min-height: 100vh;
  padding-top: 100px;
  background: -webkit-linear-gradient(
      rgba(232, 237, 255, 0.9),
      rgba(239, 254, 255, 0.9) 50%,
      rgba(254, 255, 244, 0.3) 88%,
      rgba(255, 255, 255, 0.12)
    ),
    url(${imgBg});

  background: -o-linear-gradient(
      rgba(232, 237, 255, 0.9),
      rgba(239, 254, 255, 0.9) 50%,
      rgba(254, 255, 244, 0.3) 88%,
      rgba(255, 255, 255, 0.12)
    ),
    url(${imgBg});
  background: linear-gradient(
      rgba(232, 237, 255, 0.9),
      rgba(239, 254, 255, 0.9) 50%,
      rgba(254, 255, 244, 0.3) 88%,
      rgba(255, 255, 255, 0.12)
    ),
    url(${imgBg});
  background-size: cover;
  background-repeat: no-repeat;
`;

export const Content = styled.div`
  background: white;
  margin: 1em;
  border-radius: 1em;
`;

export const PageTitle = styled.h1`
  margin: 2rem 0;
  font-size: 2.5rem;
  text-align: center;
`;
export const ResultTitle = styled.h2`
  border-top: 1px solid #015efd;
  text-align: center;
  margin-bottom: 0.5em;
  background: white;
  padding: 0.4em;
  border-radius: 5px;
  box-shadow: 0 1px 30px rgb(0 0 0 / 20%);
  backdrop-filter: blur(9.5px);
`;

export const PlanContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 1em;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
`;

export const Plan = styled.div`
  background: white;
  width: 25em;
  box-sizing: border-box;
  text-align: center;
  margin: 1em;
  margin-bottom: 1em;
`;
export const TitleContainer = styled.div`
  background-color: #f3f3f3;
  padding: 1.1em;
`;
export const SubCategories = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #f3f3f3;
  font-weight: bold;
`;
export const Title = styled.div`
  font-size: 1em;
  text-transform: uppercase;
  color: #1abc9c;
  font-weight: 700;
  padding: 1em;
`;
export const Table = styled.table`
  width: 100%;
`;
export const Tabelas = styled.div`
  th {
    width: calc(100% / 3);
    overflow-wrap: break-word;
  }
  td {
    overflow-wrap: break-word;
  }
  .columns {
    float: left;
    width: 33.3%;
    padding: 8px;
  }

  .price {
    list-style-type: none;
    background: white;
    border: 1px solid #eee;
    margin: 0;
    padding: 0;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }

  .price:hover {
    box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.2);
  }

  .price .header {
    background-color: rgb(1 94 253);
    color: white;
    font-size: 25px;
  }

  .price li {
    border-bottom: 1px solid #eee;
    padding: 20px;
    text-align: center;
  }

  .price .grey {
    background-color: #eee;
    font-size: 20px;
  }

  .button {
    background-color: #04aa6d;
    border: none;
    color: white;
    padding: 10px 25px;
    text-align: center;
    text-decoration: none;
    font-size: 18px;
  }

  @media only screen and (max-width: 1000px) {
    .columns {
      width: 50%;
    }
  }

  @media only screen and (max-width: 600px) {
    .columns {
      width: 100%;
    }
  }

  .text-primary {
    color: #015efd;
  }
  .card {
    box-shadow: 0 1px 30px rgb(0 0 0 / 20%);
    backdrop-filter: blur(9.5px);
    -webkit-backdrop-filter: blur(9.5px);
    transition: 0.2s all;
    &:hover {
      transform: scale(1.02);
      background: #ffffff;
      box-shadow: 0 1px 30px #025dfd7a;
      backdrop-filter: blur(9.5px);
      -webkit-backdrop-filter: blur(9.5px);
    }
  }
`;

export const Card = styled.div`
  margin: 1em;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  padding-bottom: 2em;
  /* filter: drop-shadow(0 0 0.4rem #025dfd); */
`;

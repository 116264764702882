import { useState } from "react";

import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import utils from "../../../helpers/utils";
import robotModel from "../../../models/robot.model";
import robotecacMode from "../../../models/robotecac.mode";

export default function CarteiraColaborador() {
  const [form, setForm] = useState({
    initDate: "2023-01-01",
    finalDate: "2023-12-31",
    columnName: "ID",
    disabled: true,
  });
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([]);

  async function setFile(target) {
    const file = await utils.GetBase64(target.files[0]);
    const smf = { ...form, disabled: false, file: file.split(",")[1] };
    setForm(smf);
  }

  const handleFileChange = async (event) => {
    await setFile(event.target);
  };

  async function submit() {
    setLoading(true);
    await robotModel.downloadClientWallt(form);
    setLoading(false);
  }

  return (
    <>
      <h5>Relatório de Faturamento</h5>
      <br />
      <Form.Label htmlFor="inputPassword5">Periodo de Vigência:</Form.Label>
      <Row>
        <Col>
          <Form.Label htmlFor="inputPassword5">Inicio:</Form.Label>
          <Form.Control
            type="date"
            value={form.initDate}
            onChange={({ target }) =>
              setForm({ ...form, initDate: target.value })
            }
          />
        </Col>
        <Col>
          <Form.Label htmlFor="inputPassword5">Termino:</Form.Label>
          <Form.Control
            type="date"
            value={form.finalDate}
            onChange={({ target }) =>
              setForm({ ...form, finalDate: target.value })
            }
          />
        </Col>
      </Row>

      <br />
      <Row>
        <Col>
          <Form.Label htmlFor="inputPassword5">
            Planilha do Colaborador:
          </Form.Label>
          <Form.Control
            type="file"
            accept=".xlsx"
            // value={form.file}
            onChange={(e) => handleFileChange(e)}
          />
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <Button disabled={loading || form.disabled} onClick={() => submit()}>
            {loading ? (
              <Spinner size="sm" animation="border" role="status" />
            ) : null}
            Enviar
          </Button>{" "}
        </Col>
      </Row>
    </>
  );
}

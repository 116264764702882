import axios from "axios";
import AuthService from "./auth";

const robot = axios.create({
  baseURL: "http://localhost:5000",
});

// Configuração global do axios
robot.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
robot.defaults.headers.common["Access-Control-Allow-Methods"] =
  "GET, POST, PUT, DELETE";
robot.defaults.headers.common["Access-Control-Allow-Headers"] = "*";
robot.defaults.headers.common["Access-Control-Max-Age"] = 86400;

// Instância do AuthService
const authService = new AuthService();

// Interceptor de requisição
robot.interceptors.request.use(async (config) => {
  const jwtAuth = await authService.getToken();
  if (jwtAuth) {
    // Faz uma cópia do objeto config antes de modificá-lo
    const newConfig = { ...config };
    newConfig.headers.Authorization = `Bearer ${jwtAuth}`;
    return newConfig;
  }
  return config;
});

export default robot;

import axios from "axios";
import AuthService from "./auth";

const api = axios.create({
  baseURL: "https://fedwenmtpd.execute-api.us-east-1.amazonaws.com",
});

// Configuração global do axios
api.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
api.defaults.headers.common["Access-Control-Allow-Methods"] =
  "GET, POST, PUT, DELETE";
api.defaults.headers.common["Access-Control-Allow-Headers"] = "*";
api.defaults.headers.common["Access-Control-Max-Age"] = 86400;

// Instância do AuthService
const authService = new AuthService();

// Interceptor de requisição
api.interceptors.request.use(async (config) => {
  const jwtAuth = await authService.getToken();
  if (jwtAuth) {
    // Faz uma cópia do objeto config antes de modificá-lo
    const newConfig = { ...config };
    newConfig.headers.Authorization = `Bearer ${jwtAuth}`;
    return newConfig;
  }
  return config;
});

export default api;

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
import "./Dashboard.css";
import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import logo from "../../assets/images/site/logo.png";

const Sidebar = () => {
  const history = useHistory();
  const iconLinkRefs = useRef([]);
  const [user, setUser] = useState({
    name: "",
    role: "",
  });
  const [isRh, setIsRh] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const onLogout = () => {
    localStorage.removeItem("EASY-TOKEN");
    localStorage.removeItem("EASY-USER");
    history.push("/app/login");
  };

  useEffect(() => {
    try {
      const userStr = localStorage.getItem("EASY-USER");
      if (userStr) {
        const { name, roles } = JSON.parse(userStr);
        console.log(roles);
        if (roles.some((role) => role.name.toLowerCase() === "rh")) {
          setIsRh(true);
        }
        if (roles.some((role) => role.name.toLowerCase() === "administrador")) {
          setIsAdmin(true);
        }
        console.log(roles);
        setUser({
          name,
          role: roles[0].name,
        });
      }
    } catch (e) {
      throw new Error("Error parsing user data from localStorage", e);
    }
  }, []);

  useEffect(() => {
    const handleIconLinkClick = (e) => {
      const iconLinkParent = e.target.closest(".iocn-link").parentElement;
      iconLinkParent.classList.toggle("showMenu");
    };

    iconLinkRefs.current.forEach((iconLink) => {
      iconLink.addEventListener("click", handleIconLinkClick);
    });

    return () => {
      iconLinkRefs.current.forEach((iconLink) => {
        iconLink.removeEventListener("click", handleIconLinkClick);
      });
    };
  }, []);

  return (
    <>
      <div className="sidebar">
        <div className="logo-details">
          <img src={logo} className="logo-sidebar" alt="Logo" />
          <span className="logo_name">Sistema Online</span>
        </div>
        <ul className="nav-links">
          <li className={`${isRh && !isAdmin ? "ocultar" : ""}`}>
            <a href="/app/dashboard">
              <i className="bx bx-grid-alt" />
              <span className="link_name">Dashboard</span>
            </a>
            <ul className="sub-menu blank">
              <li>
                <a className="link_name" href="/app/dashboard">
                  Dashboard
                </a>
              </li>
            </ul>
          </li>
          <li className={`${isRh && !isAdmin ? "ocultar" : ""}`}>
            <div
              className="iocn-link"
              ref={(el) => iconLinkRefs.current.push(el)}
            >
              <a href="#">
                <i className="bx  bx-note" />
                <span className="link_name">Nota Fiscal (NF-e)</span>
              </a>
              <i className="bx bxs-chevron-down arrow" />
            </div>
            <ul className="sub-menu">
              <li>
                <a className="link_name" href="#">
                  Nota Fiscal (NF-e)
                </a>
              </li>
              <li>
                <a href="/app/nf-e/emitir">Emitir</a>
              </li>
              <li>
                <a href="/app/tools/nfe-lote">Emitir Lote</a>
              </li>
              <li>
                <a href="/app/nf-e/list">Notas Emitidas</a>
              </li>
            </ul>
          </li>
          <li className={`${isRh && !isAdmin ? "ocultar" : ""}`}>
            <div
              className="iocn-link"
              ref={(el) => iconLinkRefs.current.push(el)}
            >
              <a href="#">
                <i className="bx bxs-wrench" />
                <span className="link_name">Ferramentas</span>
              </a>
              <i className="bx bxs-chevron-down arrow" />
            </div>
            <ul className="sub-menu">
              <li>
                <a className="link_name" href="#">
                  Ferramentas
                </a>
              </li>
              <li>
                <a href="/app/corretor-ofx">Corretor de OFX</a>
              </li>
              <li>
                <a href="/app/colab-wallet">Relatório de Faturamento</a>
              </li>
              <li>
                <a href="/app/tools/consulta-receita">Buscar Cliente</a>
              </li>
              <li>
                <a href="/app/conversor">Conversor OFX / Extrato</a>
              </li>
              <li>
                <a href="/app/tools/guias-ecac-colaborador">
                  E-CAC Extrator de Guias
                </a>
              </li>
            </ul>
          </li>
          <li className={`${isRh && !isAdmin ? "ocultar" : ""}`}>
            <div
              className="iocn-link"
              ref={(el) => iconLinkRefs.current.push(el)}
            >
              <a href="#">
                <i className="bx bx-data" />
                <span className="link_name">Fonte de Dados</span>
              </a>
              <i className="bx bxs-chevron-down arrow" />
            </div>
            <ul className="sub-menu">
              <li>
                <a className="link_name" href="#">
                  Fonte de Dados
                </a>
              </li>
              <li>
                <a href="/app/bi/fonte-dados-bi">Geral (PBI)</a>
              </li>
              <li>
                <a href="/app/parametrizacao-lp">Calculadora Site</a>
              </li>
            </ul>
          </li>
          <li className={`${isRh && !isAdmin ? "ocultar" : ""}`}>
            <div
              className="iocn-link"
              ref={(el) => iconLinkRefs.current.push(el)}
            >
              <a href="#">
                <i className="bx bx-chip" />
                <span className="link_name">Robôs</span>
              </a>
              <i className="bx bxs-chevron-down arrow" />
            </div>
            <ul className="sub-menu">
              <li>
                <a className="link_name" href="#">
                  Robôs
                </a>
              </li>
              <li>
                <a href="/app/tools/guia-das">E-CAC - Emissão DAS</a>
              </li>
              <li>
                <a href="/app/robot-ecac">E-CAC - Captura Guias</a>
              </li>
              <li>
                <a href="/app/situacao-fiscal-ecac">E-CAC - Situação Fiscal</a>
              </li>
              <li>
                <a href="/app/robots">Dominio - Faturamento</a>
              </li>
            </ul>
          </li>
          <li className={`${isRh && !isAdmin ? "ocultar" : ""}`}>
            <div
              className="iocn-link"
              ref={(el) => iconLinkRefs.current.push(el)}
            >
              <a href="#">
                <i className="bx bx-barcode" />
                <span className="link_name">Inventário</span>
              </a>
              <i className="bx bxs-chevron-down arrow" />
            </div>
            <ul className="sub-menu">
              <li>
                <a className="link_name">Inventário</a>
              </li>
              <li>
                <a href="/app/inventario/listar">Listar Inventário</a>
              </li>
              <li>
                <a href="/app/inventario/listar">Adicionar Item</a>
              </li>
              <li>
                <a href="/app/inventario/listar">Vincular Item</a>
              </li>
            </ul>
          </li>
          <li className={`${isRh || isAdmin ? "" : "ocultar"}`}>
            <div
              className="iocn-link"
              ref={(el) => iconLinkRefs.current.push(el)}
            >
              <a href="#">
                <i className="bx bx-buildings" />
                <span className="link_name">RH</span>
              </a>
              <i className="bx bxs-chevron-down arrow" />
            </div>
            <ul className="sub-menu">
              <li>
                <a className="link_name">RH</a>
              </li>
              <li>
                <a href="/app/rh/banco-de-talentos">Banco de talentos</a>
              </li>
            </ul>
          </li>
          <li className={`${isRh && !isAdmin ? "ocultar" : ""}`}>
            <div
              className="iocn-link"
              ref={(el) => iconLinkRefs.current.push(el)}
            >
              <a href="#">
                <i className="bx bx-user" />
                <span className="link_name">Usuários</span>
              </a>
              <i className="bx bxs-chevron-down arrow" />
            </div>
            <ul className="sub-menu">
              <li>
                <a className="link_name" href="#">
                  Usuários
                </a>
              </li>
              <li>
                <a href="/app/usuarios/listar">Listar Usuários</a>
              </li>
              <li>
                <a href="/app/usuarios/cadastrar">Cadastrar Usuário</a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#">
              <i className="bx bx-cog" />
              <span className="link_name">Configurações</span>
            </a>
            <ul className="sub-menu blank">
              <li>
                <a className="link_name" href="#">
                  Configurações
                </a>
              </li>
            </ul>
          </li>
          <li>
            <div className="profile-details">
              <div className="name-job">
                <div className="profile_name">{user?.name}</div>
                <div className="job">{user?.role}</div>
              </div>
              <span onClick={onLogout}>
                <i className="bx bx-log-out" />
              </span>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Sidebar;

/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import IframePlayer from "player-iframe-video";
import { Link } from "react-router-dom";

import Button from "../../Global/Form/Button";

import {
  BannerHero,
  Container,
  Content,
  Title,
  Description,
  ButtonRow,
  VideoContainer
} from "./styles";

export default function Banner() {

  const iFrame = <iframe width="100%" height="100%"
                         src="https://player.vimeo.com/video/707772958?h=72d483cbe8&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;loop=1"
                         frameBorder="0"
                         allow="autoplay; fullscreen; picture-in-picture"
                         allowFullScreen
                         title="Assista a consultoria que preparamos para você" />

  return (
    <BannerHero>
      <Container>
        <Content>
          <Title>Assista a consultoria que preparamos para você</Title>
          <VideoContainer>
          <IframePlayer id="iframe-consultoria-gravada" iFrame={iFrame} />
          </VideoContainer>

          <Description>
            Acreditamos em uma gestão financeira transparente e descomplicada. faça uma simulação clicando no botão abaixo
          </Description>
          <ButtonRow>
            <Link to="/simular" style={{display: "contents"}}>
            <Button
              width={50}
              primary
              color="#025DFD"
              type="submit"
            >
              Quero simular meus impostos
            </Button>
            </Link>

          </ButtonRow>
        </Content>
      </Container>
    </BannerHero>
  );
}

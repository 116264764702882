import { BsArrowDown } from "react-icons/bs";
import { Link } from "react-scroll";
import { Container } from "./styles";

export default function OverlayBar() {
  return (
    <Container>
      <Link href="solutions" to="solutions" smooth offset={-100} duration={200}>
        <div className="content">
          <p>Funciona Assim</p>
          <BsArrowDown color="#fff" size={20} />
        </div>
      </Link>
    </Container>
  );
}

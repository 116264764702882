import React from "react";
import { SwitchContainer } from "./styles";

const Switch = () => (
  <SwitchContainer>
    <div className="switch">
      <input
        id="impulso"
        type="checkbox"
        aria-label="Ganhe mais tempo para sua carreira médica"
        checked
      />
      <span className="slider round" />
    </div>
  </SwitchContainer>
);

export default Switch;

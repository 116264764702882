import React, { useState } from "react";
import ToolsModel from "../../../models/tools.model";
import "./style.css";
import CadastroGrid from "./CadastroGrid";
import { Spinner } from "react-bootstrap";
import FaturamentoGrid from "./FaturamentoGrid";

const CnpjSearchPage = () => {
  const [cnpj, setCnpj] = useState("");
  const [data, setData] = useState(null);
  const [cadastro, setCadastro] = useState(null);
  const [load, setLoad] = useState(false);
  const [faturamento, setFaturamento] = useState([]);

  const handleSearch = async () => {
    // Remove quaisquer caracteres não numéricos do CNPJ
    const cnpjNumerico = cnpj.replace(/\D/g, "");
    setLoad(true);
    const f = await ToolsModel.getCnpj(cnpjNumerico);
    setData(f?.receita ?? null);
    setCadastro(f?.cadastro ?? null);
    setFaturamento(f?.faturamento?.slice(0, 100));

    setLoad(false);
  };

  return (
    <div>
      <h4>Buscar Cliente</h4>
      <span>Consulta global de clientes.</span>
      <br />
      <br />
      <div className="row mb-3">
        <div className="col-md-5">
          <label>CNJP:</label>
          <input
            type="text"
            className="form-control"
            placeholder="Digite o CNPJ"
            value={cnpj}
            onChange={(e) => setCnpj(e.target.value)}
          />
        </div>
        <div className="col-md-3">
          <br />
          <button
            disabled={load}
            className="btn btn-primary btn-block"
            onClick={handleSearch}
          >
            {load ? (
              <Spinner size="sm" animation="border" role="status" />
            ) : null}{" "}
            Pesquisar
          </button>
        </div>
      </div>
      <br />
      {cadastro ? (
        <>
          <h5>Dados de Cadastro</h5>
          <div className="card card-body">
            <CadastroGrid data={cadastro} />
          </div>
          <br />
        </>
      ) : null}
      {data && (
        <>
          <h5>Dados da Receita</h5>
          <div className="mt-4 card card-body">
            <div className="row">
              <script />
              <div className="col-md-6 data-contain">
                <span className="span-data">
                  <strong>Abertura:</strong> {data?.abertura}
                </span>
                <span className="span-data">
                  <strong>Situação:</strong> {data?.situacao}
                </span>
                <span className="span-data">
                  <strong>Tipo:</strong> {data?.tipo}
                </span>
                <span className="span-data">
                  <strong>Nome:</strong> {data?.nome}
                </span>
                <span className="span-data">
                  <strong>Fantasia:</strong> {data?.fantasia}
                </span>
                <span className="span-data">
                  <strong>Porte:</strong> {data?.porte}
                </span>
                <span className="span-data">
                  <strong>Natureza Jurídica:</strong> {data?.natureza_juridica}
                </span>
                <span className="span-data">
                  <strong>Quadro Societário:</strong>
                </span>
                <div style={{ padding: 10 }}>
                  <ul>
                    {data?.qsa?.map((socio, index) => (
                      <li key={index}>
                        <strong>Nome:</strong> {socio?.nome ?? ""},{" "}
                        <strong>Qualificação:</strong> {socio?.qual ?? ""}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="col-md-6 data-contain">
                <span className="span-data">
                  <strong>Logradouro:</strong> {data?.logradouro}
                </span>
                <span className="span-data">
                  <strong>Número:</strong> {data?.numero}
                </span>
                <span className="span-data">
                  <strong>Complemento:</strong> {data?.complemento}
                </span>
                <span className="span-data">
                  <strong>Município:</strong> {data?.municipio}
                </span>
                <span className="span-data">
                  <strong>Bairro:</strong> {data?.bairro}
                </span>
                <span className="span-data">
                  <strong>UF:</strong> {data?.uf}
                </span>
                <span className="span-data">
                  <strong>CEP:</strong> {data?.cep}
                </span>
                <span className="span-data">
                  <strong>Email:</strong> {data?.email}
                </span>
                <span className="span-data">
                  <strong>Telefone:</strong> {data?.telefone}
                </span>
                <span className="span-data">
                  <strong>Data Situação:</strong> {data?.data_situacao}
                </span>
                <span className="span-data">
                  <strong>Motivo Situação:</strong> {data?.motivo_situacao}
                </span>
                <span className="span-data">
                  <strong>CNPJ:</strong> {data?.cnpj}
                </span>
                <span className="span-data">
                  <strong>Última Atualização:</strong>{" "}
                  {data?.ultima_atualizacao}
                </span>
                <span className="span-data">
                  <strong>Status:</strong> {data?.status}
                </span>
                <span className="span-data">
                  <strong>EFR:</strong> {data?.efr}
                </span>
                <span className="span-data">
                  <strong>Situação Especial:</strong> {data?.situacao_especial}
                </span>
                <span className="span-data">
                  <strong>Data Situação Especial:</strong>{" "}
                  {data?.data_situacao_especial}
                </span>
                {data?.atividades_secundarias ? (
                  <>
                    <span className="span-data">
                      <strong>Atividade Principal:</strong>{" "}
                      {data?.atividade_principal[0].text}
                    </span>
                    <span className="span-data">
                      <strong>Atividades Secundárias:</strong>{" "}
                      {data?.atividades_secundarias[0].text}
                    </span>
                  </>
                ) : null}

                <span className="span-data">
                  <strong>Capital Social:</strong> {data?.capital_social ?? ""}
                </span>
              </div>
            </div>
          </div>
          <br />
        </>
      )}
      {faturamento && faturamento.length > 0 ? (
        <>
          <h5>{faturamento.length} últimos faturamentos</h5>
          <div className="card faturamneto-grid">
            <FaturamentoGrid data={faturamento} />
          </div>
          <br />
        </>
      ) : null}
    </div>
  );
};

export default CnpjSearchPage;

import { useState } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import utils from "../../../helpers/utils";
import biModel from "../../../models/bi.model";
import "./style.css";

const ORIGINAL_STATE_MESSAGE = {
    err: false,
    msg: ""
}

function UploadBi() {

    const [load, setLoad] = useState(false);
    const [message, setMessage] = useState(ORIGINAL_STATE_MESSAGE)

    const [formBi, setFormBi] = useState({
        file: "",
        type: ""
    })

    function handlerMsg(msg) {
        setMessage(msg);

        setTimeout(() => {
            setMessage(ORIGINAL_STATE_MESSAGE)
        }, 4000);
    }


    async function submitForm() {
        setLoad(true);
        const res = await biModel.upload(formBi);
        const err = { msg: "Erro ao alterar fonte de dados.", err: true };
        const ace = { msg: "Fonte de dados alterada com sucesso, atualiza o PowerBi para vializar os novos dados." }
        handlerMsg(res ? ace : err);
        setLoad(false);
    }

    async function setFile(target) {
        const file = await utils.GetBase64(target.files[0])

        setFormBi({ ...formBi, file: file.split(",")[1] });
    }




    return (
        <>
            <div className="bi-header-div">
                <span className="h3 bi-header">Fonte de Dados BI</span>
                <span className="bi-header-description">Atualiza as bases de dados para o Power BI</span>
            </div>

            <Form className="bi-form-data">
                <Row>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>Fonte de Dados para atualização:</Form.Label>
                            <Form.Select className="form-bi" onChange={({ target }) => setFormBi({ ...formBi, type: target.value })} >
                                <option />
                                <option value="faturamentoClientes">Faturamento Clientes</option>
                                <option value="cadastro">Clientes</option>
                                <option value="faturamento">Faturamento</option>
                                <option value="NPS">NPS</option>

                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" >
                            <Form.Label>Arquivo para atualização (SOMENTE .csv):</Form.Label>
                            <Form.Control type="file" accept=".csv" onChange={({ target }) => setFile(target)} />
                        </Form.Group>
                    </Col>

                </Row>

                {message.msg !== "" ? <div className={`alert alert-${!message.err ? "success" : "danger"}`} role="alert">
                    {message.msg}
                </div> : null}

                <Button primary color="#025DFD" disabled={load} onClick={() => submitForm()}>
                    {!load ? "Enviar" : <Spinner animation="border" role="status" style={{ width: 24, height: 24 }} />}
                </Button>

            </Form>
        </>
    )
}


export default UploadBi;
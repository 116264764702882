import styled from "styled-components";
import { Link } from "react-scroll";

import imgBg from "../../../assets/images/site/bg-site.webp";

export const BannerHero = styled.section`
  margin: 0 auto;
  min-height: 100vh;

  background: -webkit-linear-gradient(
      rgba(232, 237, 255, 0.9),
      rgba(239, 254, 255, 0.9) 50%,
      rgba(254, 255, 244, 0.3) 88%,
      rgba(255, 255, 255, 0.12)
    ),
    url(${imgBg});

  background: -o-linear-gradient(
      rgba(232, 237, 255, 0.9),
      rgba(239, 254, 255, 0.9) 50%,
      rgba(254, 255, 244, 0.3) 88%,
      rgba(255, 255, 255, 0.12)
    ),
    url(${imgBg});
  background: linear-gradient(
      rgba(232, 237, 255, 0.9),
      rgba(239, 254, 255, 0.9) 50%,
      rgba(254, 255, 244, 0.3) 88%,
      rgba(255, 255, 255, 0.12)
    ),
    url(${imgBg});
  background-size: cover;
  background-repeat: no-repeat;
`;

export const Container = styled.div`

padding: 8em 5em;

  /* max-width: 1280px;
  min-height: 550px;
  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 1280px) {
    padding: 0 2rem;
  } */
  @media screen and (max-width: 1280px) {
    padding: 8em 1em;
  }

`;

export const Content = styled.div`
  margin: 0 auto;
`;

export const SubTitle = styled.div`
  strong {
    margin-right: 10px;
    font-size: 1rem;
    font-weight: normal;
    line-height: 2rem;
    text-transform: uppercase;
    color: var(--cc-btn-primary-hover-bg);
  }
`;

export const Title = styled.h1`
  font-size: 3rem;
  color: var(--cc-text);
  margin-bottom: 1em;
  text-align: center;
`;

export const Description = styled.p`
  margin: 25px 0;
  color: var(--cc-text);
  filter: brightness(150%);
  font-size: 1rem;
  font-weight: 300;
  text-align: center;
`;

export const PlansPrice = styled.span`
  color: #86888d;
  font-weight: bold;

  strong {
    color: #ffffff;
  }
`;

export const ButtonToNextSectionContainer = styled.div`
  height: 32px;
  margin-top: 3rem;

  display: flex;
  align-items: center;

  line-height: 32px;

  p {
    margin-right: 1rem;
  }
`;


export const ButtonToNextSection = styled(Link)`
display: flex;
justify-content:center ;
align-items:center ;
  height: 40px;
  cursor: pointer;
  color: var(--cc-text-title);
  p {
    margin: 0px ;
    margin-right: 1em ;
  }

.icon {
  display: flex;
    padding: 0.6em;
    border-radius: 5em;
    background: #015efd;
    &:hover{
      background: #0b1f3e;
    }
}
`;

export const ButtonRow = styled.div`
display: flex;
justify-content: center;
gap: 2em;
`;

export const VideoContainer = styled.div`
  height: 60vh;
`;

import axios from "axios";
import AuthService from "./auth";

// Configuração global do axios
axios.defaults.baseURL =
  "https://e77n81fbgf.execute-api.us-east-1.amazonaws.com";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.common["Access-Control-Allow-Methods"] =
  "GET, POST, PUT, DELETE";
axios.defaults.headers.common["Access-Control-Allow-Headers"] = "*";
axios.defaults.headers.common["Access-Control-Max-Age"] = 86400;

// Instância do AuthService
const authService = new AuthService();

// Interceptor de requisição
axios.interceptors.request.use((config) => {
  const jwtAuth = authService.getToken();
  if (jwtAuth) {
    // Faz uma cópia do objeto config antes de modificá-lo
    const newConfig = { ...config };
    newConfig.headers.Authorization = `Bearer ${jwtAuth}`;
    return newConfig;
  }
  return config;
});

export default axios;

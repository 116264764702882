import React from "react";
import { useForm } from "react-hook-form";
import { FaGoogle, FaFacebook } from "react-icons/fa";

function Marketing() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    // Aqui você pode lidar com os dados submetidos, por exemplo, enviá-los para o backend
    console.log(data);
  };
  return (
    <div>
      <h1>Marketing</h1>
      <hr className="mb-5" />
      <form
        className="row g-3 needs-validation"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="row mb-3">
          <div className="col-md-4">
            <label htmlFor="validationCustomUsername" className="form-label">
              Google Tag Manager
            </label>
            <div className="input-group has-validation">
              <span className="input-group-text" id="gtm">
                <FaGoogle />
              </span>
              <input
                type="text"
                className={`form-control ${errors.gtm ? "is-invalid" : ""}`}
                id="gtm"
                aria-describedby="gtm"
                {...register("gtm", { required: true })}
              />
              {errors.gtm && (
                <div className="invalid-feedback">Insira um GTM válido.</div>
              )}
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-4">
            <label htmlFor="validationCustomUsername" className="form-label">
              Meta Pixel
            </label>
            <div className="input-group has-validation">
              <span className="input-group-text" id="pixel">
                <FaFacebook />
              </span>
              <input
                type="text"
                className={`form-control ${errors.pixel ? "is-invalid" : ""}`}
                id="pixel"
                aria-describedby="pixel"
                {...register("pixel", { required: true })}
              />
              {errors.pixel && (
                <div className="invalid-feedback">Insira um Pixel válido.</div>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-2">
            <button type="submit" className="btn btn-primary">
              Salvar
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Marketing;

import React from "react";
import Carousel from "react-elastic-carousel";
import CardSolution from "./CardSolution";
import data from "../../../data/dataSolution";
import { Container, Content, Header } from "./styles";

export default function Solucoes() {
  const renderCards = () =>
    data.map((item) => (
      <CardSolution
        key={item.id}
        price={item.price}
        image={item.image}
        category={item.category}
        title={item.title}
        subTitle={item.subTitle}
        badge={item.badge}
        link={item.link}
        buttonType={item.buttonType}
      />
    ));
  const breakpoints = [
    {
      width: 1,
      itemsToShow: 1,
      showArrows: false,
      pagination: true,
      itemPadding: [20, 10],
    },
    {
      width: 550,
      itemsToShow: 2,
      itemsToScroll: 2,
      showArrows: true,
      pagination: false,
      itemPadding: [20, 10],
    },
    { width: 850, itemsToShow: 3, itemPadding: [20, 10] },
    { width: 1150, itemsToShow: 4, itemsToScroll: 4, itemPadding: [20, 10] },
  ];
  return (
    <Container id="solutions">
      <Content>
        <Header>
          <h1>Conheça nossas soluções</h1>
        </Header>
        <Carousel itemsToShow={4} breakPoints={breakpoints} pagination={false}>
          {renderCards()}
        </Carousel>
      </Content>
    </Container>
  );
}

import React from "react";
import { ActionsContainer, Container, GridInfo, GridItem } from "./styles";

const ListarInventario = () => (
  <Container>
    <h3>Listagem do inventário</h3>
    <GridInfo>
      <GridItem>
        <h4>Total</h4>
        <span>20</span>
      </GridItem>
      <GridItem>
        <h4>Disponivel</h4>
        <span>20</span>
      </GridItem>
      <GridItem>
        <h4>Em Uso</h4>
        <span>20</span>
      </GridItem>
      <GridItem>
        <h4>Em Manutenção</h4>
        <span>20</span>
      </GridItem>
    </GridInfo>
    <ActionsContainer>
      <button type="button">
        <i className="bx bx-plus" />
        <span>Adicionar Item</span>
      </button>
    </ActionsContainer>
  </Container>
);

export default ListarInventario;

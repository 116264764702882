import styled from "styled-components";

export const Nav = styled.nav`
  background-color: white;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.1);

  a {
    height: 100%;
    padding: 0 30px;
    text-decoration: none;
    display: flex;
    align-items: center;
    color: black;
    color: #025dfd;
    font-size: 1rem;
    line-height: 2rem;
    border: none;
    border-radius: 0.5rem;
    transition: 0.2s ease-in-out;
    &:hover {
      background-color: #025dfd;
      color: #fff;
    }
  }
  @media(min-width: 900px) {
    a {
      padding: 0 10px;
      font-size: 0.9rem;
    }
  }

  li {
    height: 50px;
    &:first-child {
      margin-right: auto;
    }
  }

  ul {
    width: 100%;
    list-style: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .menu-desktop {
    position: fixed;
    padding: 1em 4em;
    background-color: #fff;
    z-index: 999;
    gap: 1em;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  }

  .menu-button {
    button {
      background-color: transparent;
      border: none;
    }
  }
`;

export const Sidebar = styled.ul``;

import React from "react";
import { Link } from "react-router-dom";
import { Container, Main, Fof } from "./styles";

export default function Error() {
  return (
    <Container>
      <Main>
      <Fof>
          <h1>Erro 404</h1>
          <p style={{ textAlign: "center" }}>
          <Link to="/">Clique aqui para voltar a página inicial</Link>
        </p>
        </Fof>

      </Main>

    </Container>
  );
}

import React, { useState } from "react";
import * as XLSX from "xlsx";

import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import parametrizacaoModel from "../../../models/parametrizacao.model";

const ORIGINAL_STATE_MESSAGE = {
  err: false,
  msg: "",
};

export default function Parametrizacao() {
  const [planilha, setPlanilha] = useState(false);
  const [load, setLoad] = useState(false);
  const [message, setMessage] = useState(ORIGINAL_STATE_MESSAGE);

  const handleFile = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[2];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      const filteredData = jsonData
        .filter((row) => row.some(Boolean))
        .map((row) => row.filter((value) => value !== null));
      setPlanilha(filteredData);
    };
    reader.readAsArrayBuffer(file);
  };

  function handlerMsg(msg) {
    setMessage(msg);

    setTimeout(() => {
      setMessage(ORIGINAL_STATE_MESSAGE);
    }, 4000);
  }

  async function submitForm(type = "parametrizacao", payload) {
    setLoad(true);
    const res = await parametrizacaoModel.upload(type, payload);
    const err = { msg: "Erro ao alterar fonte de dados.", err: true };
    const ace = {
      msg: "Fonte de dados alterada com sucesso, a calculadora foi atualizada com os novos dados.",
    };
    handlerMsg(res ? ace : err);
    setLoad(false);
    setLoad(false);
  }

  const handleUpdateCalculadora = () => {
    const payload = {
      inss: {
        TetoInss: planilha[3][0],
        PagamentoMaximoInss: planilha[3][1],
      },
      irpf: {
        ValorInicial: planilha[3][2],
        faixas: [
          {
            id: 1,
            limite_inferior: 0,
            limite_superior: planilha[3][2],
            aliquota: 0,
            deducao: 0,
          },
          {
            id: 2,
            limite_inferior: planilha[3][2],
            limite_superior: planilha[4][1],
            aliquota: 7.5,
            deducao: 142.8,
          },
          {
            id: 3,
            limite_inferior: planilha[5][0],
            limite_superior: planilha[5][1],
            aliquota: 15,
            deducao: 354.8,
          },
          {
            id: 4,
            limite_inferior: planilha[6][0],
            limite_superior: planilha[6][1],
            aliquota: 22.5,
            deducao: 636.13,
          },
          {
            id: 5,
            limite_inferior: planilha[7][0],
            limite_superior: null,
            aliquota: 27.5,
            deducao: 869.36,
          },
        ],
      },
    };

    submitForm("parametrizacao", payload);
  };

  return (
    <>
      <div className="bi-header-div">
        <span className="h3 bi-header">Parametrização Calculadora</span>
        <span className="bi-header-description">
          Atualiza dados dinâmicos utilizados na landing page.
        </span>
      </div>

      <Form className="bi-form-data">
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Fonte de Dados:</Form.Label>
              <Form.Select className="form-bi">
                <option value="calculadora">Calculadora</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Planilha de atualização:</Form.Label>
              <Form.Control type="file" accept=".xlsx" onChange={handleFile} />
            </Form.Group>
          </Col>
        </Row>

        {message.msg !== "" ? (
          <div
            className={`alert alert-${!message.err ? "success" : "danger"}`}
            role="alert"
          >
            {message.msg}
          </div>
        ) : null}

        <Button
          primary
          color="#025DFD"
          disabled={load && !planilha}
          onClick={() => handleUpdateCalculadora()}
        >
          {!load ? (
            "Enviar"
          ) : (
            <Spinner
              animation="border"
              role="status"
              style={{ width: 24, height: 24 }}
            />
          )}
        </Button>
      </Form>
    </>
  );
}

import { BrowserRouter as Router } from "react-router-dom";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Toaster } from "react-hot-toast";
import Routes from "./routes";
import { GlobalStyles } from "./styles/global";

import "./assets/css/app.css";
import { NavbarScrollContextProvider } from "./context/NavbarScrollContext";

import ModalVideo from "./components/HelpModal";

export function App() {
  return (
    <NavbarScrollContextProvider>
      <Router>
        <GlobalStyles />
        <Routes />
        <ModalVideo />
        <Toaster position="top-center" reverseOrder={false} />
      </Router>
    </NavbarScrollContextProvider>
  );
}

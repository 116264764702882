import React, { useState } from "react";

import { Button, Form } from "react-bootstrap";

import nexus from "../../../../services/nexus";
import nexus2 from "../../../../services/nexus2";

const NfeLote = () => {
  const [csvFile, setCsvFile] = useState(null);
  const [ofxFile, setOfxFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = (event) => {
    setCsvFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    const reader = new FileReader();

    reader.onloadend = async () => {
      const base64String = reader.result.split(",")[1]; // Obter a string base64 sem o prefixo

      const requestBody = {
        csv: base64String,
      };

      try {
        const { data } = await nexus2.post("/nfe/emissao-lote", requestBody, {
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "blob",
        });

        if (data) {
          window.location.href = "/app/nf-e/list";
        }
      } catch (error) {
        console.error("Erro ao enviar requisição:", error);
      }

      setIsLoading(false);
    };

    reader.readAsDataURL(csvFile);
  };

  return (
    <div>
      <h5>Emissão NFE em Lote</h5>
      <span>
        Emite notas fiscais em lote baseado{" "}
        <a
          target="_blank"
          style={{ color: "blue", textDecoration: "underline" }}
          href="https://easydoc-files.s3.amazonaws.com/templates_nexus/template_planilha_nfe.xlsx"
        >
          nesse template.
        </a>
      </span>
      <br />
      <br />
      <form onSubmit={handleSubmit}>
        <Form.Control type="file" onChange={handleFileChange} />
        <br />
        <Button type="submit" disabled={!csvFile || isLoading}>
          {isLoading ? "Enviando..." : "Enviar"}
        </Button>
      </form>
    </div>
  );
};

export default NfeLote;

import { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import Spinner from "react-bootstrap/Spinner";

// eslint-disable-next-line import/no-extraneous-dependencies
import { toast } from "react-hot-toast";
import authModel from "../../../models/auth.model";
import AuthService from "../../../services/auth";

import { Main } from "./styles";
import logo from "../../../assets/images/site/logo.png";

export default function Login() {
  const [load, setLoading] = useState(false);

  const history = useHistory();
  const { register, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    setLoading(true);
    const res = await authModel.login(data);
    if (res.status) {
      const authServic = new AuthService();
      if (authServic.login(res)) {
        const userRoles = res.user?.roles || [];
        console.log(userRoles);
        return history.push("/app/dashboard");
      }
    } else {
      toast.error("E-mail ou senha inválidos.");
    }
    return setLoading(false);
  };
  return (
    <Main>
      <div className="center">
        <img
          src={logo}
          className="logo-image"
          alt="Logo EasyDr. Contabilidade Médica"
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="txt_field">
            <input type="text" id="email" {...register("email")} required />
            <span />
            <label htmlFor="email">E-mail</label>
          </div>
          <div className="txt_field">
            <input type="password" id="senha" {...register("senha")} required />
            <span />
            <label htmlFor="senha">Senha</label>
          </div>
          <div className="pass">Esqueceu a senha?</div>
          <button type="submit" disabled={load} className="login-btn">
            {load ? (
              <>
                <Spinner
                  animation="border"
                  role="status"
                  style={{ width: 24, height: 24 }}
                />
                <span>Autenticando</span>
              </>
            ) : (
              <span>Autenticar</span>
            )}
          </button>
        </form>
      </div>
    </Main>
  );
}

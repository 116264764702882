import React, { useEffect, lazy, Suspense } from "react";
import { useLocation, useHistory } from "react-router-dom";
import Header from "../../components/Home/Header";

import Banner from "../../components/Home/Banner";
import Button from "../../components/Home/Button";
import Footer from "../../components/Home/Footer";
import Solucoes from "../../components/Home/Solucoes";
import OverlayBar from "../../components/Home/Subscribebar";
import { useNavbarScroll } from "../../hooks/useNavbarScroll";
import Vagas from "../../components/Home/Vagas";

const OndeEstamos = lazy(() => import("../../components/Home/OndeEstamos"));
const Timeline = lazy(() => import("../../components/Home/Timeline"));
const Contratar = lazy(() => import("../../components/Home/Contratar"));
const Testimonials = lazy(() => import("../../components/Home/Testimonials"));
const Faq = lazy(() => import("../../components/Home/Faq"));

export default function Home() {
  const { isVisible } = useNavbarScroll();
  const { hash } = useLocation();
  const renderLoader = () => <p>Loading</p>;

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const query = useQuery();
  const history = useHistory();

  if (query.get("source") === "rdstationcalculadora") {
    localStorage.setItem("leadcalculadora", "true");
  }

  if (query.get("source") === "liberarConsultoria") {
    localStorage.setItem("consultoria", "true");
    history.push("/consultoria-gravada");
  }

  if (query.get("politica") === "open") {
    history.push("/politica-de-privacidade");
  }

  useEffect(() => {
    if (!localStorage.getItem("coins-calculadora")) {
      localStorage.setItem("coins-calculadora", 2);
    }
  }, []);

  useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash.replace('#', ''));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [hash]);

  return (
    <>
      <Header />
      <main>
        <Banner />
        <Solucoes />
        <Suspense fallback={renderLoader()}>
          <OndeEstamos />
          <Timeline />
          <Contratar />
          <Testimonials />
          <Vagas />
          <Faq />
        </Suspense>
      </main>
      <Button />
      <Footer />
      {!isVisible && <OverlayBar />}
    </>
  );
}

import styled from "styled-components";
import { Link } from "react-scroll";

export const BannerHero = styled.section`
  margin: 0 auto;
  min-height: 100vh;

  background: #fff;
`;

export const Container = styled.div`
  max-width: 1400px;
  height: calc(100vh - 100px);
  min-height: 628px;
  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4em;
  @media screen and (max-width: 1280px) {
    padding: 0 2rem;
  }
`;

export const Content = styled.div`
  width: 60%;
  margin-top: 10em;
  @media screen and (max-width: 900px) {
    width: 100%;
  }
`;

export const SubTitle = styled.div`
  strong {
    margin-right: 10px;
    font-size: 1rem;
    font-weight: normal;
    line-height: 2rem;
    text-transform: uppercase;
    color: var(--cc-btn-primary-hover-bg);
  }
`;

export const Title = styled.h1`
  font-family: Montserrat, sans-serif;
  text-align: center;
  font-weight: bold;
  font-size: 4rem;
  color: var(--cc-text);
  @media screen and (max-width: 900px) {
    font-size: 3rem;
  }
  @media screen and (max-width: 360px) {
    font-size: 2.5rem;
  }
`;

export const Description = styled.p`
  font-family: "Montserrat", sans-serif;
  text-align: center;
  margin: 25px 65px;
  color: var(--cc-text);
  filter: brightness(150%);
  font-size: 1.5rem;
  font-weight: 500;
  @media screen and (max-width: 900px) {
    font-size: 1.4rem;
  }
  @media screen and (max-width: 360px) {
    margin: 15px 30px;
  }
  @media screen and (max-width: 376px) {
    margin: 15px 55px;
  }
`;

export const PlansPrice = styled.span`
  color: #86888d;
  font-weight: bold;

  strong {
    color: #ffffff;
  }
`;

export const ButtonToNextSectionContainer = styled.div`
  height: 32px;
  margin-top: 3rem;

  display: flex;
  align-items: center;

  line-height: 32px;

  p {
    margin-right: 1rem;
  }
`;

export const BannerImage = styled.div`
  width: 40%;
  margin: 0 auto;
  margin-top: 10em;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 900px) {
    display: none;
  }
`;

export const Image = styled.img`
  width: 80%;
`;

export const ButtonToNextSection = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  cursor: pointer;
  color: var(--cc-text-title);
  p {
    margin: 0px;
    margin-right: 1em;
  }

  .icon {
    display: flex;
    padding: 0.6em;
    border-radius: 5em;
    background: #015efd;
    &:hover {
      background: #0b1f3e;
    }
  }
`;

export const ButtonRow = styled.div`
  display: flex;
  gap: 2em;
  justify-content: center;
  width: 50%;
  margin: 0 auto;

  @media screen and (max-width: 360px) {
    width: 70%;
  }
`;

export const SwitchContainer = styled.div`
  text-align: center;
  margin-top: 1.3em;
`;

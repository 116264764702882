import axios from "axios";
import AuthService from "./auth";

const nexus2 = axios.create({
  baseURL: "https://of92e844r7.execute-api.us-east-1.amazonaws.com",
});

// Configuração global do axios
nexus2.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
nexus2.defaults.headers.common["Access-Control-Allow-Methods"] =
  "GET, POST, PUT, DELETE";
nexus2.defaults.headers.common["Access-Control-Allow-Headers"] = "*";
nexus2.defaults.headers.common["Access-Control-Max-Age"] = 86400;

// Instância do AuthService
const authService = new AuthService();

// Interceptor de requisição
nexus2.interceptors.request.use(async (config) => {
  const jwtAuth = await authService.getToken();
  if (jwtAuth) {
    // Faz uma cópia do objeto config antes de modificá-lo
    const newConfig = { ...config };
    newConfig.headers.Authorization = `Bearer ${jwtAuth}`;
    return newConfig;
  }
  return config;
});

export default nexus2;

import styled from "styled-components";
import backgroundImage from "../../assets/images/vagas/headervaga.webp";

export const Header = styled.header`
  max-width: 1200px;
  margin: 0px auto;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  flex-direction: column;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ActionsContainer = styled.div`
  button {
    --primary-color: #025dfd;
    --secondary-color: #fff;
    --hover-color: #111;
    --arrow-width: 10px;
    --arrow-stroke: 2px;
    box-sizing: border-box;
    border: 0;
    border-radius: 20px;
    color: var(--secondary-color);
    padding: 0.6em 1em;
    background: var(--primary-color);
    display: flex;
    transition: 0.2s background;
    align-items: center;
    gap: 0.6em;
    font-weight: bold;
    opacity: 0.6;
  }

  button .arrow-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  button .arrow {
    margin-top: 1px;
    width: var(--arrow-width);
    background: var(--primary-color);
    height: var(--arrow-stroke);
    position: relative;
    transition: 0.2s;
  }

  button .arrow::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    border: solid var(--secondary-color);
    border-width: 0 var(--arrow-stroke) var(--arrow-stroke) 0;
    display: inline-block;
    top: -3px;
    right: 3px;
    transition: 0.2s;
    padding: 3px;
    transform: rotate(-45deg);
  }

  button:hover {
    opacity: 1;
  }

  button:hover .arrow {
    background: var(--secondary-color);
  }

  button:hover .arrow:before {
    right: 0;
  }
`;

export const Menu = styled.header`
  margin-top: 1em;
  display: flex;
  justify-content: space-between;
  img {
    width: 5em;
  }

  .button {
    display: inline-block;
    padding: 0.75rem 1.25rem;
    border-radius: 10rem;
    color: #fff;
    text-transform: uppercase;
    font-size: 1rem;
    letter-spacing: 0.15rem;
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #025dfd;
      border-radius: 10rem;
      z-index: -2;
    }
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0%;
      height: 40px;
      background-color: darken(#025dfd, 15%);
      transition: all 0.3s;
      border-radius: 10rem;
      z-index: -1;
    }
    &:hover {
      color: #fff;
      &:before {
        width: 100%;
      }
    }
  }
`;

export const HeroContainer = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  height: 200px;
  align-items: center;
`;

export const Container = styled.div`
  width: 100%;
  height: 250px;
  background-image: url(${backgroundImage});
  background-size: cover;
  padding: 20px;
`;

export const AboutContainer = styled.div`
  max-width: 1200px;
  margin: 2em auto;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  flex-direction: column;
  h1 {
    font-weight: 500;
    font-size: 1.5rem;
    margin-left: 3em;
  }

  h2 {
    font-weight: 700;
    font-size: 1rem;
  }

  h3 {
    font-weight: 500;
    font-size: 1.5rem;
  }

  p {
    font-size: 1.1rem;
    font-weight: 300;
    margin-top: 1rem;
    margin-left: 4em;
  }

  .category {
    font-size: 0.9rem;
    font-weight: 400;
  }

  .job-details {
    margin-left: 5em;
    margin-right: 5em;
    margin-bottom: 4em;
  }
  @media only screen and (max-width: 768px) {
    h1 {
      margin-left: 1em;
    }

    p {
      margin-left: 21px;
    }

    .job-details {
      margin-left: 2em;
      margin-right: 2em;
    }
  }
`;

export const Title = styled.h1`
  font-weight: bold;
  color: white;
  font-weight: 500;
  text-align: center;
`;

export const Separator = styled.span``;
export const Jobname = styled.span``;

export const FormContainer = styled.div`
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: column;
  padding: 2em;
  h1 {
    font-size: 2em;
    font-weight: 600;
    margin-bottom: 1em;
  }
`;

export const Footer = styled.footer`
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  background: rgb(241, 241, 241);
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  border-top: 1px solid rgb(238, 238, 238);
  box-shadow: rgb(170, 170, 170) 0px -6px 17px 2px;
  height: 90px;
`;

export const FileInputContainer = styled.div`
  margin-bottom: 20px;
`;
export const DrawerContainer = styled.div`
  .EZDrawer__containerdrawer-container {
    height: 100% !important;
  }
`;

export const FileInputLabel = styled.label`
  display: inline-block;
  cursor: pointer;
  color: #333;
  background-color: #f5f5f5;
  padding: 10px 15px;
  border-radius: 5px;
  border: 2px solid #ddd;
  transition: background-color 0.3s;

  &:hover {
    background-color: #e0e0e0;
  }
`;

export const ErrorMessage = styled.p`
  color: red;
  margin-top: 5px;
`;

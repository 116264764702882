import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { BiHelpCircle } from "react-icons/bi";
import { getVideo } from "../../contants/video_helper";

const ModalVideo = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const video = getVideo(window.location.pathname);

  if (!video) return <></>;

  return (
    <>
      <Button
        style={{ position: "absolute", bottom: 20, left: 20, zIndex: 9999 }}
        variant="primary"
        onClick={() => setModalIsOpen(true)}
      >
        <BiHelpCircle /> Ajuda
      </Button>
      <Modal show={modalIsOpen} onHide={() => setModalIsOpen(false)} size="lg">
        {" "}
        <Modal.Body>
          <iframe
            title="Ajuda do Sistema"
            src={video.url_iframe}
            width="100%"
            height="360"
            frameBorder="0"
            scrolling="no"
            allowfullscreen
          />
          <br />
          <div style={{ width: "100%", padding: 5 }}>
            <span style={{ textAlign: "justify" }}>{video.description}</span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalIsOpen(false)}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ModalVideo;

import { useState } from "react";

import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import utils from "../../../helpers/utils";
import robotecacMode from "../../../models/robotecac.mode";

function GuiasEacColaborador() {
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [form, setForm] = useState({
    file: "",
    column: "",
  });

  async function setFile(target) {
    setLoading(true);
    const file = await utils.GetBase64(target.files[0]);
    const smf = { ...form, file: file.split(",")[1] };
    setForm(smf);
    const cols = await robotecacMode.get_columns(smf);
    setColumns(cols);
    setLoading(false);
  }

  async function submit() {
    setLoading(true);
    const final = await robotecacMode.get_reporst_zip(form);
    setLoading(false);
  }

  const handleFileChange = async (event) => {
    await setFile(event.target);
    try {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.reader.readAsText(file);
    } catch {}
  };

  return (
    <>
      <h5>Extrator de guias de E-cac</h5>
      <br />
      <Row>
        <Col md={7}>
          <Form.Group>
            <Form.Label htmlFor="inputPassword5">
              Planilha do Colaborador:
            </Form.Label>
            <Form.Control
              type="file"
              accept=".xlsx, .xls"
              // value={form.file}
              onChange={(e) => handleFileChange(e)}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label htmlFor="inputPassword5">
              Coluna "CNPJ" da sua planilha:
            </Form.Label>
            <Form.Select
              type="select"
              disabled={!columns || columns?.length === 0}
              onChange={({ target }) => {
                setForm({
                  ...form,
                  column: target.value,
                });
              }}
            >
              <option />
              {columns.map((e) => (
                <option value={e}>{e}</option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <br />

      <Row>
        <Col>
          <Button disabled={loading} onClick={() => submit()}>
            {loading ? (
              <Spinner size="sm" animation="border" role="status" />
            ) : null}
            Enviar
          </Button>{" "}
        </Col>
      </Row>
    </>
  );
}

export default GuiasEacColaborador;

/* eslint-disable no-unused-vars */
import React from "react";
import { useHistory } from "react-router-dom";
import { Card, Description, Name, Services, Button } from "./styles";

const CardSolution = ({ title, subTitle, active, job }) => {
  const history = useHistory();
  const handleClick = () => {
    history.push(`/vaga/${job.slug}`, { job });
  };

  if (!active) return null;
  return (
    <Card>
      <Description>
        <Name>{title}</Name>
        <Services>{subTitle}</Services>
        <Button onClick={handleClick}>
          <span>Acessar Vaga</span>
        </Button>
      </Description>
    </Card>
  );
};

export default CardSolution;

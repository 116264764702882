import { useEffect, useState } from "react";
import { Col, Form, Row, Button, Spinner } from "react-bootstrap";
import robotModel from "../../../models/robotecac.mode";
import utils from "../../../helpers/utils";

function RobotEcac() {
  const [status, setStatus] = useState("DEFAULT");

  const [config, setConfigs] = useState({});
  const [loading, setLoading] = useState(false);
  const actualDate = utils.getFirstAndLastDayOfMonth();

  const [form, setForm] = useState({
    initDate: actualDate.first.toISOString().split("T")[0],
    finalDate: actualDate.last.toISOString().split("T")[0],
  });

  async function init() {
    const data = await robotModel.alive();
    setStatus(data.status);
    setConfigs(data);
  }

  async function handler_reset() {
    init();
    setInterval(() => {
      init();
    }, 20000);
  }
  useEffect(() => {
    handler_reset();
  }, []);

  async function initRobot() {
    setLoading(true);
    const data = await robotModel.init(form);
    await init();
    setLoading(false);
  }

  async function stopRobot() {
    setLoading(true);
    const data = await robotModel.stop();
    await init();
    setLoading(false);
  }

  async function initRelatorio() {
    setLoading(true);
    const data = await robotModel.report();
    setLoading(false);
  }

  async function removeAnalised() {
    setLoading(true);
    await robotModel.cleanCache();
    init();
    setLoading(false);
  }

  return (
    <>
      <h5>Status do robô: {config?.total ? "Funcionando" : "Desligado"}</h5>
      <label>Horário de Funcionamento: 18:00 as 08:00 (SOMENTE)</label>
      <br />
      <br />
      <Row>
        <Col>
          <Form.Label htmlFor="inputPassword5">Inicio:</Form.Label>
          <Form.Control
            type="date"
            disabled={!config?.total}
            value={form.initDate}
            onChange={({ target }) =>
              setForm({ ...form, initDate: target.value })
            }
          />
        </Col>
        <Col>
          <Form.Label htmlFor="inputPassword5">Termino:</Form.Label>
          <Form.Control
            type="date"
            disabled={!config?.total}
            value={form.finalDate}
            onChange={({ target }) =>
              setForm({ ...form, finalDate: target.value })
            }
          />
        </Col>
      </Row>
      <br />
      <br />
      {config?.total ? (
        <Row>
          <span>Total de empresas: {config?.total}</span>
          <span>Já analisados: {config?.cache}</span>
          <span>Faltantes: {config?.total - config?.cache}</span>
          <span>Robô: {config?.active ? "Ativo" : "Inativo"}</span>
          <span>Status: {config?.msg ?? "-"}</span>
        </Row>
      ) : null}
      <br /> <br />
      <Row>
        {config?.total ? (
          <Col>
            <Button
              disabled={loading}
              onClick={() => (!config?.active ? initRobot() : stopRobot())}
            >
              {loading ? (
                <Spinner size="sm" animation="border" role="status" />
              ) : null}{" "}
              {!config?.active ? "Iniciar Robô" : "Parar Robô"}
            </Button>{" "}
            <Button disabled={loading} onClick={() => initRelatorio()}>
              {loading ? (
                <Spinner size="sm" animation="border" role="status" />
              ) : null}{" "}
              Gerar Relatório
            </Button>{" "}
            <Button disabled={loading} onClick={() => removeAnalised()}>
              {loading ? (
                <Spinner size="sm" animation="border" role="status" />
              ) : null}{" "}
              Limpar Analisados
            </Button>
          </Col>
        ) : null}
      </Row>
    </>
  );
}

export default RobotEcac;

import nexus from "../services/nexus";

async function upload({ file, type }) {

    try {
        const { data } = await nexus.post("/blob-storange/create", { planilha: file, type });
        return data;
    }
    catch (e) {

        return {
            status: false,
            token: "",
            error: "Error"
        }
    }

}


export default {
    upload,
};

import React from "react";
import Header from "../../components/Home/Header";
import HeaderConsultoria from "../../components/Home/HeaderConsultoria";

export function Consultoria() {
  return (
    <>
      <Header />
      <HeaderConsultoria />
    </>
  );
}

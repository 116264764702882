import React from "react";
import { Form, Button, Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const EditarUsuario = () => {
  const location = useLocation();
  const { user } = location.state;

  const onSubmit = (data) => {
    console.log(data);
  };

  const createUserFormSchema = z.object({
    users: z.object({
      nome: z.string().min(2, "Por favor, insira um nome válido.").max(255, "Limite de caracteres atingidos."),
      email: z.string().min(1, "Por favor, insira um email válido."),
      idade: z.string()
      .transform((val) => parseInt(val, 10))
      .refine((val) => Number.isNaN(val), {
        message: "Por favor, insira uma idade válida."
      })
      .refine((val) => val >= 1, {
        message: "Por favor, insira uma idade válida."
      }),
      cargo: z.string().min(1, "Por favor, insira um cargo válido."),
      status: z.boolean(),
    }),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(createUserFormSchema),
    defaultValues: {
      users: {
        nome: user.nome,
        email: user.email,
        idade: user.idade,
        cargo: user.cargo,
        status: user.status,
      },
    },
  });

  console.log(errors);

  return (
    <Container>
      <div className="mt-4 mb-4">
        <h5>Editar Usuário:</h5>
      </div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group controlId="nome" className="mt-2">
          <Form.Label>Nome*</Form.Label>
          <Form.Control
            type="text"
            name="nome"
            {...register("users.nome")}
            defaultValue={user.nome}
            required
          />
        </Form.Group>
        {errors.users?.nome?.message && (
            <p style={{color: 'red', marginTop: '0.5em'}}>{errors.users?.nome?.message}</p>
          )}

        <Form.Group controlId="email" className="mt-2">
          <Form.Label>Email*</Form.Label>
          <Form.Control
            type="email"
            name="email"
            {...register("users.email")}
            defaultValue={user.email}
            required
          />
        </Form.Group>
        {errors.users?.email?.message && (
            <p style={{color: 'red', marginTop: '0.5em'}}>{errors.users?.email?.message}</p>
          )}

        <Form.Group controlId="idade" className="mt-2">
          <Form.Label>Idade*</Form.Label>
          <Form.Control
            type="number"
            name="idade"
            {...register("users.idade")}
            defaultValue={user.idade}
            required
          />
        </Form.Group>
        {errors.users?.idade?.message && (
            <p style={{color: 'red', marginTop: '0.5em'}}>{errors.users?.idade?.message}</p>
          )}

        <Form.Group controlId="cargo" className="mt-2">
          <Form.Label>Cargo*</Form.Label>
          <Form.Control
            type="text"
            name="cargo"
            {...register("users.cargo")}
            defaultValue={user.cargo}
            required
          />
        </Form.Group>
        {errors.users?.cargo?.message && (
            <p style={{color: 'red', marginTop: '0.5em'}}>{errors.users?.cargo?.message}</p>
          )}

        <Form.Group controlId="status">
          <Form.Label>Status</Form.Label>
          <div>
            <Form.Check
              type="checkbox"
              id="ativo"
              label="Ativo"
              name="status"
              value="Ativo"
              defaultValue={user.status}
              {...register("users.status")}
            />
          </div>
        </Form.Group>

        <Button variant="primary" className="mt-4" type="submit">
          Salvar
        </Button>
      </Form>
    </Container>
  );
};

export default EditarUsuario;

import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { toast } from "react-hot-toast";
import vagasModel from "../../../models/vagas.model";

function BancoDeTalentos() {
  const [resumes, setResumes] = useState([]);
  const [pending, setPending] = useState(true);

  const getResumes = async () => {
    try {
      setPending(true);

      const res = await vagasModel.getResumes();
      if (res.data) {
        toast.success("Currículos obtidos com sucesso!", {
          duration: 5000,
        });
        setResumes(res.data);
      } else {
        toast.error("Erro ao recuperar currículos tente novamente mais tarde.");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setPending(false);
    }
  };

  useEffect(() => {
    getResumes();
  }, []);

  function handleCvDownload(row) {
    if (row && row.resume) {
      // Abre a URL contida em 'row.resume' em uma nova aba
      window.open(row.resume, "_blank");
    } else {
      console.log("O objeto 'row' ou a propriedade 'resume' não está definido");
    }
  }

  const columns = [
    {
      name: "Nome",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Vaga",
      selector: (row) => row.jobtitle,
      sortable: true,
    },
    {
      name: "E-Mail",
      selector: (row) => row.mail,
      sortable: true,
    },
    {
      name: "Telefone",
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: "Curriculo",
      selector: (row) => (
        <button
          type="button"
          onClick={() => handleCvDownload(row)}
          className="btn btn-primary btn-sm"
        >
          Baixar CV
        </button>
      ),

      sortable: true,
    },
  ];

  return (
    <>
      <h5>Banco de Talentos EasyDr.</h5>
      <br />
      <DataTable
        columns={columns}
        data={resumes}
        fixedHeader
        title="Currículos em nossa base de dados"
        pagination
        selectableRows
        progressPending={pending}
      />
    </>
  );
}

export default BancoDeTalentos;

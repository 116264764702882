

function Dashboard() {

    return (
        <div style={{ overflowY: "hidden" }}>
            <h6>Power BI</h6>
            <iframe title="EasyDr" width="100%" height="520" src="https://app.powerbi.com/reportEmbed?reportId=47001757-15c3-400b-9603-68838d1193db&autoAuth=true&ctid=39217e32-b027-4395-8300-019d1b676d78" frameborder="0" allowFullScreen="true"></iframe>
        </div>
    )
}


export default Dashboard
import styled from "styled-components";

export const Main = styled.main`
  margin: 0;
  padding: 0;
  height: 100vh;
  overflow: hidden;

  background: linear-gradient(-45deg, #d2e3f2, #a9cce3, #7eb0d7, #5588ba);
  background-size: 400% 400%;
  animation: gradientBG 10s ease infinite;

  @keyframes gradientBG {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  .logo-image {
    width: 100%;
    padding: 1em 8em 0em;

    @media (max-width: 700px) {
      padding: 1em 6em 0em;
    }
  }

  .center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 450px;

    background: white;
    border-radius: 10px;
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.05);
    padding: 1em;
    @media (max-width: 700px) {
      min-width: 90%;
    }
  }

  .center h1 {
    text-align: center;
    padding: 20px 0;
    border-bottom: 1px solid silver;
  }
  .center form {
    padding: 0 40px;
    box-sizing: border-box;
    @media (max-width: 700px) {
      padding: 0px;
    }
  }
  form .txt_field {
    position: relative;
    border-bottom: 2px solid #adadad;
    margin: 30px 0;
  }
  .txt_field input {
    width: 100%;
    padding: 0 5px;
    height: 40px;
    font-size: 16px;
    border: none;
    background: none;
    outline: none;
  }
  .txt_field label {
    position: absolute;
    top: 50%;
    left: 5px;
    color: #adadad;
    transform: translateY(-50%);
    font-size: 16px;
    pointer-events: none;
    transition: 0.5s;
  }
  .txt_field span::before {
    content: "";
    position: absolute;
    top: 40px;
    left: 0;
    width: 0%;
    height: 2px;
    background: #025dfd;
    transition: 0.5s;
  }
  .txt_field input:focus ~ label,
  .txt_field input:valid ~ label {
    top: -5px;
    color: #025dfd;
  }
  .txt_field input:focus ~ span::before,
  .txt_field input:valid ~ span::before {
    width: 100%;
  }
  .pass {
    margin: -5px 0 20px 5px;
    color: #a6a6a6;
    cursor: pointer;
  }
  .pass:hover {
    text-decoration: underline;
  }
  button[type="submit"] {
    width: 100%;
    height: 50px;
    border: 1px solid;
    background: rgb(2, 93, 253);
    border-radius: 25px;
    font-size: 18px;
    color: rgb(233, 244, 251);
    font-weight: 700;
    cursor: pointer;
    display: flex;
    outline: none;
    justify-content: center;
    align-content: center;
    align-items: center;
    gap: 0.5em;
  }

  button[type="submit"]:hover {
    border-color: #2691d9;
    transition: 0.5s;
  }

  .btnIcon {
    display: flex;
    justify-content: center;
    align-items: end;
    gap: 1em;
}
`;

export const Center = styled.div`
  width: 400px;
  background: white;
  border-radius: 10px;

  h1 {
    text-align: center;
    padding: 0 0 20px 0;
    border-bottom: 1px solid silver;
  }

  form {
    padding: 0 40px;
    box-sizing: border-box;
    .txt_field {
      position: relative;
      border-bottom: 2px solid #adadad;
      margin: 30px 0;

      input {
        width: 100%;
        padding: 0 5px;
        height: 40px;
        font-size: 16px;
        border: none;
        background: none;
        outline: none;
      }
    }
  }
`;

export const Container = styled.section`
  width: 100vw;
  margin: 0 auto;
  padding: 0 2rem;
  min-height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  div {
    width: 100%;
    max-width: 384px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
  }

  img {
    margin: 2rem 0;
  }

  h2 {
    font-size: 1rem;
    text-transform: uppercase;
    margin: 0.5rem 0;
  }

  h1 {
    font-size: 2rem;
    text-align: center;
    font-weight: normal;
    margin-bottom: 3rem;
  }

  form,
  label {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  form {
    width: 100%;
    font-weight: 300;
    //background: aquamarine;

    label {
      width: 100%;
      align-items: flex-start;
      padding: 0.5rem 0;
    }

    a {
      align-self: flex-end;
      width: 100%;
    }

    input {
      width: 100%;
      height: 48px;
      background: rgba(32, 34, 39, 0.9);
      border-radius: 6px;
      padding: 12px 20px;
      letter-spacing: -0.005em;
      color: #ffffff;
      font-size: 1rem;
      border: 1px solid transparent;
      margin: 10px 0;

      &:focus {
        outline: none;
      }
    }

    .recpassword {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-direction: row;

      font-weight: 300;
      font-size: 0.9rem;

      height: 20px;

      img {
        margin-left: 15px;
      }
    }
  }

  h5:before {
    content: "";
    display: block;
    border-top: solid 1px #aaa;
    width: 100%;
    height: 1px;
    position: absolute;
    top: 50%;
    z-index: 1;
  }

  h5 {
    position: relative;
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 1rem;
  }

  span {
    background: #000;
    padding: 0 20px;
    position: relative;
    z-index: 5;
  }
  .logo-image {
    width: 100px;
    margin-bottom: 0px;
    margin-top: -10px;
  }
  .label-login-easy {
    margin-bottom: -15px;
  }
`;

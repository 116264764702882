import axios from "axios";
import robot from "../services/robot";
import nexus from "../services/nexus";
import { saveAs } from 'file-saver';

async function initRobotDominio(form) {

    try {
        const { data } = await robot.post("/robot/init", form);
        return data;
    }
    catch (e) {
        return false;
    }

}


async function initReport() {

    try {
        const response = await robot.get('/robot/reports', {
            responseType: 'blob', // Define o tipo de resposta como blob (binary data)
        });

        // Cria um link temporário para fazer o download do arquivo
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'relatorio.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    catch (e) {
        return false;
    }

}

const downloadClientWallt = async (body) => {
    try {
        const response = await nexus.post('/wallet-clients/report', body, {
            responseType: 'blob' // Indica que a resposta será um objeto Blob
        });

        // Extrai o nome do arquivo do header Content-Disposition
        const contentDisposition = response.headers['content-disposition'];
        const filename = "Faturamento_Clientes.xlsx";

        // Cria um objeto Blob com os dados da resposta
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Salva o arquivo usando o pacote file-saver
        saveAs(blob, filename);
    } catch (e) {
        console.log(e)
        return ""
    }
};



async function alive() {

    try {
        const { data } = await robot.get("/");

        return data;

    }
    catch (e) {
        return {
            status: false,
            total: 0,
            cache: 0,
            active: false,
            msg: ""
        };
    }

}

async function removeAnalised() {
    try {
        const { data } = await robot.get("/robot/clean-cache");
        return true;
    }
    catch (e) {
        return false
    }
}



async function stopRobot() {
    try {
        const { data } = await robot.get("/robot/stop");
        return true;
    }
    catch (e) {
        return false
    }
}

export default {
    initRobotDominio, initReport, alive, removeAnalised, stopRobot, downloadClientWallt
};

import styled from "styled-components";

export const Container = styled.div`
  padding: 0px 20px 0px 20px;

  h3 {
    font-weight: 700;
  }
`;

export const GridInfo = styled.div`
  margin-top: 1em;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 25px;
  grid-row-gap: 0px;
`;

export const GridItem = styled.div`
  padding: 1em;
  background-color: white;
  box-shadow: 0px 0px 20px -3px rgba(0, 0, 0, 0.1);

  h4 {
    font-size: 1.4em;
  }

  span {
    font-weight: 700;
    font-size: 2em;
  }
`;

export const ActionsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 1em;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 20px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    height: 55px;
    text-align: center;
    border: none;
    background-size: 300% 100%;
    border-radius: 50px;
    moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    background-image: linear-gradient(
      to right,
      #002463,
      #15479e,
      #002463,
      #15479e
    );
    box-shadow: 0 4px 15px 0 rgba(65, 132, 234, 0.75);

    &:hover {
      background-position: 100% 0;
      moz-transition: all 0.4s ease-in-out;
      -o-transition: all 0.4s ease-in-out;
      -webkit-transition: all 0.4s ease-in-out;
      transition: all 0.4s ease-in-out;
    }

    i {
      font-size: 1.8em;
    }
  }
`;

import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  ListGroup,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { FaEdit, FaSave } from "react-icons/fa";

import { FiDownload } from "react-icons/fi";
import utils from "../../../helpers/utils";
import robotecacMode from "../../../models/robotecac.mode";

function GuiaDas() {
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [simulacao, setSimulation] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [selectItem, setSelectItem] = useState(null);

  const [form, setForm] = useState({
    file: "",
    run: [],
    exceptions_id: [],
  });

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    simular();
  }, [form.file]);

  async function init() {
    const data = await robotecacMode.list_result_das();
    setResults(data);
  }

  async function changeValueExceptions(id, name, value) {
    const { exceptions_id } = form;
    const verify = exceptions_id.filter((e) => e.id === id);

    if (verify.length > 0) {
      setForm({
        ...form,
        exceptions_id: exceptions_id.map((e) => {
          if (id === e.id) {
            return {
              ...e,
              [name]: value,
            };
          }
          return e;
        }),
      });
    } else {
      setForm({
        ...form,
        exceptions_id: [...form.exceptions_id, { id, [name]: value }],
      });
    }
    setSimulation([...simulacao]);
  }

  async function simular() {
    if (!form.file) {
      return;
    }
    setLoading(true);
    setSimulation([]);
    const data = await robotecacMode.simular_simples_nacional(form);
    setSimulation(data.map((item) => ({ ...item, run: false })));
    setLoading(false);
  }

  async function setFile(target) {
    const file = await utils.GetBase64(target.files[0]);
    const smf = { ...form, file: file.split(",")[1] };
    setForm(smf);
  }

  async function submit() {
    setLoading(true);
    const final = await robotecacMode.simples_nascional_init(form);
    setLoading(false);
  }

  async function download(name) {
    await robotecacMode.download_guia_das(name);
  }

  const handleFileChange = async (event) => {
    await setFile(event.target);
    try {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsText(file);
    } catch {}
  };

  const handleCheckboxChange = (id) => {
    const updatedSimulation = simulacao.map((item) =>
      item.id === id ? { ...item, run: !item.run } : item
    );
    setSimulation(updatedSimulation);
    const runIds = updatedSimulation
      .filter((item) => item.run)
      .map((item) => item.id);
    setForm({ ...form, run: runIds });
  };

  const handleCheckAllChange = (checked) => {
    const updatedSimulation = simulacao.map((item) => ({
      ...item,
      run: checked,
    }));
    setSimulation(updatedSimulation);
    const runIds = checked ? updatedSimulation.map((item) => item.id) : [];
    setForm({ ...form, run: runIds });
  };

  const getItemException = (id, name, defaultValue) => {
    if (defaultValue === "") return "";
    try {
      const final = form.exceptions_id.filter((e) => e.id == id);

      if (final.length <= 0 || !final?.[0]?.[name]) {
        const parsedValue = parseFloat(
          defaultValue.toString().replace(".", "").replace(",", ".")
        );

        return isNaN(parsedValue) ? 0 : parsedValue;
      }

      const parsedValue = parseFloat(
        final[0][name].toString().replace(".", "").replace(",", ".")
      );

      return isNaN(parsedValue) ? 0 : parsedValue;
    } catch {
      return 0;
    }
  };

  return (
    <>
      <h5>E-cac Emissão DAS</h5>
      <br />
      <Row>
        <Col md={12}>
          <Form.Group>
            <Form.Label htmlFor="inputPassword5">
              Planilha do Colaborador:
            </Form.Label>
            <Form.Control
              type="file"
              accept=".xlsx, .xls"
              onChange={(e) => handleFileChange(e)}
            />
          </Form.Group>
        </Col>
      </Row>

      <div className="mt-4 mb-4">
        <div>
          <h6>Marcados para rodar:</h6>
          {form.run.length === 0 ? (
            <a style={{ marginLeft: 5 }}>
              {" "}
              <b>Nenhum item marcado</b>.
            </a>
          ) : null}
          <a>
            {form.run.map((e, index) => (
              <b>
                {e}
                {index === form.run.length - 1 ? "" : ", "}{" "}
              </b>
            ))}
          </a>
        </div>
      </div>

      <Row className="mt-4">
        <Col>
          <Button
            disabled={loading || form.run.length === 0}
            onClick={() => submit()}
          >
            {loading ? (
              <Spinner size="sm" animation="border" role="status" />
            ) : null}{" "}
            Iniciar
          </Button>{" "}
        </Col>
      </Row>
      <div className="mt-4">
        {results.length > 0 ? <h6>Resultados:</h6> : null}
        <ListGroup>
          {results.map((e) => (
            <ListGroup.Item key={e.name}>
              <a
                onClick={() => download(e.name)}
                style={{ flexDirection: "row", cursor: "pointer" }}
              >
                <FiDownload style={{ marginRight: 5 }} /> {e.name}
              </a>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </div>

      <div className="mt-4 mb-4">
        <h6>Simulação: </h6>
        <Form.Group className="mt-2 mb-2">
          <Form.Control
            type="text"
            placeholder="Busca por Nome ou Código (id)"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </Form.Group>
        <Table bordered style={{ height: "500px", overflowY: "auto" }}>
          <thead>
            <tr>
              <th>ID</th>
              <th>Nome da Empresa</th>
              <th>Valor da última folha</th>
              <th className="w-25">CPP</th>
              <th>Total</th>
              <th>301.0</th>
              <th>303.0</th>
              <th>
                <div>
                  <label>Rodar?</label>
                  {"  "}
                  <Form.Check
                    type="checkbox"
                    alt="Marcar/Desmarcar todos"
                    checked={simulacao.every((item) => item.run)}
                    onChange={(e) => handleCheckAllChange(e.target.checked)}
                  />
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {simulacao
              .filter(
                (item) =>
                  item.nome_emp
                    ?.toLowerCase()
                    .includes(searchText?.toLowerCase()) ||
                  String(item.id).includes(searchText)
              )
              .map((item) => (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>
                    {item.id !== selectItem ? (
                      <FaEdit
                        color="primary"
                        onClick={() =>
                          setSelectItem(selectItem != null ? null : item.id)
                        }
                      />
                    ) : (
                      <FaSave
                        color="primary"
                        onClick={() =>
                          setSelectItem(selectItem != null ? null : item.id)
                        }
                      />
                    )}
                    {"  "}
                    {item.nome_emp}
                  </td>

                  <td onClick={() => setSelectItem(item.id)}>
                    {item.id === selectItem ? (
                      <Form.Control
                        type="number"
                        value={getItemException(
                          item.id,
                          "valor_fechamento",
                          item.valor_fechamento
                        )
                          .toString()
                          .replace(".", "")
                          .replace(",", ".")}
                        onChange={({ target }) =>
                          changeValueExceptions(
                            item.id,
                            "valor_fechamento",
                            target.value
                          )
                        }
                      />
                    ) : (
                      getItemException(
                        item.id,
                        "valor_fechamento",
                        item.valor_fechamento
                      ) ?? ""
                    )}
                  </td>

                  <td onClick={() => setSelectItem(item.id)}>
                    {item.id === selectItem ? (
                      <Form.Control
                        type="number"
                        value={getItemException(item.id, "cpp", item.cpp)}
                        onChange={({ target }) =>
                          changeValueExceptions(item.id, "cpp", target.value)
                        }
                      />
                    ) : (
                      item.cpp ?? 0
                    )}
                  </td>

                  <td>
                    {parseFloat(getItemException(item.id, "cpp", item.cpp)) +
                      parseFloat(
                        getItemException(
                          item.id,
                          "valor_fechamento",
                          item.valor_fechamento
                        )
                      )}
                  </td>
                  <td onClick={() => setSelectItem(item.id)}>
                    {item.id === selectItem ? (
                      <Form.Control
                        type="number"
                        value={getItemException(
                          item.id,
                          "301.0",
                          item["301.0"]
                        )}
                        onChange={({ target }) =>
                          changeValueExceptions(item.id, "301.0", target.value)
                        }
                      />
                    ) : (
                      getItemException(item.id, "301.0", item["301.0"])
                    )}
                  </td>

                  <td onClick={() => setSelectItem(item.id)}>
                    {item.id === selectItem ? (
                      <Form.Control
                        type="number"
                        value={getItemException(
                          item.id,
                          "303.0",
                          item["303.0"]
                        )}
                        onChange={({ target }) =>
                          changeValueExceptions(item.id, "303.0", target.value)
                        }
                      />
                    ) : (
                      getItemException(item.id, "303.0", item["303.0"])
                    )}
                  </td>

                  <td>
                    <Form.Check
                      type="checkbox"
                      checked={item.run}
                      onChange={() => handleCheckboxChange(item.id)}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
    </>
  );
}

export default GuiaDas;

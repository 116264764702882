import nexus from "../services/nexus";

async function apply(resume) {
  try {
    const { data } = await nexus.post("/vagas/candidatar", resume);
    return {
      status: true,
      data,
    };
  } catch (e) {
    return {
      error: e,
    };
  }
}

async function getResumes() {
  try {
    const { data } = await nexus.get("/vagas");
    return {
      status: true,
      data,
    };
  } catch (e) {
    return {
      error: e,
    };
  }
}

export default {
  apply,
  getResumes,
};

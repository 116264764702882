import styled from "styled-components";

export const Container = styled.div`
  display: ${(props) => (props.isVisible ? "block" : "none")};
`;

export const WhatsappButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 65px;
  right: 15px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  font-size: 30px;
  box-shadow: 1px 1px 2px #888;
  z-index: 1000;
  transition: 0.2s all;
  &:hover {
    color: #015efd;
    transform: scale(1.1);
  }
`;
export const CalcButton = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 100px;
  right: 22px;
  background-color: #0b1e3e;
  color: #fff;
  border-radius: 50px;
  font-size: 30px;
  box-shadow: 1px 1px 2px #888;
  z-index: 1000;
  transition: 0.2s all;
  &:hover {
    transform: scale(1.1);
    color: #fff;
  }
`;

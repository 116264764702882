import styled from "styled-components";

export const Container = styled.footer`
  width: 100%;
  height: 450px;
  padding: 0 2rem;
  margin: 0 auto;

  .nav-menu-footer {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 4rem 0 2rem 0;

    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @media (max-width: 780px) {
      display: block;
      text-align: center;
    }

    section {
      .separator {
        margin: 5rem 0 1rem 0;
      }

      p {
        font-weight: 300;
      }

      ul {
        list-style: none;
      }

      li {
        margin: 2rem 0;
      }

      .link {
        &:hover {
          color: #025dfd;
        }
      }

      .logo-parceiros {
        max-width: 250px;
        @media (max-width: 780px) {
          display: block;
          text-align: center;
        }
      }

      .logo-parceiros img {
        max-width: 100%;
      }

      .logo-parceiros {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        margin: 0 auto;
      }

      .app-store {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;

        a {
          display: block;
          padding: 10px 20px;
          border: 1px solid #ffffff;
          border-radius: 6px;
          width: 170px;
          margin: 0rem 0rem 1rem 0rem;
          background-color: var(--cc-text);
          transition: 1s all;
        }
      }
    }
  }
`;

export const Copyright = styled.div`
  width: 100%;
  max-width: 1280px;
  height: 50px;
  margin: 0 auto;

  border-top: 2px solid #202227;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-top: 2rem;
  margin-top: 5rem;

  p {
    font-size: 0.9rem;
  }
  .icon {
    display: flex;
    padding: 0.6em;
    border-radius: 5em;
    background: #015efd;
    &:hover {
      background: #0b1f3e;
    }
  }
  .toTheTop {
    display: flex;
    align-items: center;

    span {
      margin-right: 1rem;
      font-weight: bold;
    }

    button {
      border: none;
      background: transparent;

      img {
        transform: rotate(180deg);
      }
    }
  }
`;

export const SocialBar = styled.div`
  display: flex;
  gap: 0.5em;
  align-items: center;
  align-items: center;
  justify-content: center;
  margin-top: 1em;
`;

export const ParceirosContainer = styled.div`
  margin-top: 1em;
  display: flex;
  gap: 1em;
  -webkit-box-align: center;
  flex-direction: column;
  align-items: center;
`;

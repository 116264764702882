// import { BsArrowUp} from "react-icons/bs";
import { Link } from "react-router-dom";
import { Container, Copyright, ParceirosContainer, SocialBar } from "./styles";
import instagramLogo from "../../../assets/images/socials/instagram.svg";
import YoutubeLogo from "../../../assets/images/socials/youtube.svg";
import FacebookLogo from "../../../assets/images/socials/facebook.svg";
import LinkedInLogo from "../../../assets/images/socials/linkedin.svg";
import appleStore from "../../../assets/images/site/apple_footer.svg";
import googleStore from "../../../assets/images/site/google_footer.svg";
import logocfm from "../../../assets/images/site/cfm.png";
import logosobrahm from "../../../assets/images/site/logo-sobrahm-1.webp";

export default function Footer() {
  const currentYear = new Date().getFullYear();
  // function scrollToTop() {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth",
  //   });
  // }

  return (
    <Container>
      <div className="nav-menu-footer">
        <section>
          <strong>Transparência</strong>
          <div style={{ marginTop: "1em" }}>
            <Link
              to="//easydoc-files.s3.amazonaws.com/documentos/politicaprivacidade.pdf"
              target="_blank"
            >
              <span className="link">Aviso de Privacidade</span>
            </Link>
          </div>
        </section>
        <section>
          <strong>Redes Sociais</strong>
          <SocialBar>
            <a
              href="https://www.instagram.com/easydr.financasmedicas/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="hoverImage"
                src={instagramLogo}
                alt="Instagram"
                width={50}
              />
            </a>
            <a
              href="https://www.youtube.com/@easydr.contabilidademedica"
              target="_blank"
              rel="noreferrer"
            >
              <img src={YoutubeLogo} alt="Youtube" width={50} />
            </a>
            <a
              href="https://www.facebook.com/easydr.contabilidademedica"
              target="_blank"
              rel="noreferrer"
            >
              <img src={FacebookLogo} alt="Facebook" width={50} />
            </a>
            <a
              href="https://www.linkedin.com/company/easydr/about/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={LinkedInLogo} alt="LinkedIn" width={50} />
            </a>
          </SocialBar>
        </section>
        <section>
          <strong>Parceiros</strong>
          <div className="logo-parceiros">
            <ParceirosContainer>
              <img
                src="https://www.cremerj.org.br/img/v2/layout/logoCremerj.png"
                alt="Logo Cremerj"
              />
              <img src={logocfm} alt="Logo CFM" />
              <img src={logosobrahm} alt="Logo SOBRAMH" />
            </ParceirosContainer>
          </div>
        </section>
        <section>
          <strong>Baixe Nosso App</strong>
          <div className="app-store" style={{ marginTop: "1em" }}>
            <a href="https://apps.apple.com/br/app/easydr/id1577967555">
              <img src={appleStore} alt="Apple Store" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.easydrcontabilidade">
              <img src={googleStore} alt="Google Store" />
            </a>
          </div>
        </section>
      </div>
      <Copyright>
        <p>EASYDR CONTABILIDADE LTDA | CNPJ: 37.488.162/0001-20</p>
        <p>{currentYear} © Todos os direitos reservados. </p>
      </Copyright>
    </Container>
  );
}

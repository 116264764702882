import styled from "styled-components";

export const Container = styled.aside`
  width: 100vw;
  height: 3.5em;
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 0 auto;
  padding: 0 2rem;
  background-color: rgba(2, 93, 253, 0.8);
  z-index: 10;

  .content {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    animation: aumentarTexto 4s infinite;
    -webkit-animation: aumentarTexto 4s infinite;
    -moz-animation: aumentarTexto 4s infinite;
    -o-animation: aumentarTexto 4s infinite;

    a {
      display: flex;
    }

    p {
      text-transform: uppercase;
      font-weight: 500;
      margin-bottom: 0px;
      margin-right: 0.5em;
      color: #f3f3f3;
    }
  }

  @keyframes aumentarTexto {
    50% {
      transform: scale(1.2);
    }
  }

  @-webkit-keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      -webkit-transform: translateY(0);
    }
    40% {
      -webkit-transform: translateY(-30px);
    }
    60% {
      -webkit-transform: translateY(-15px);
    }
  }

  @-moz-keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      -moz-transform: translateY(0);
    }
    40% {
      -moz-transform: translateY(-30px);
    }
    60% {
      -moz-transform: translateY(-15px);
    }
  }

  @-o-keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      -o-transform: translateY(0);
    }
    40% {
      -o-transform: translateY(-30px);
    }
    60% {
      -o-transform: translateY(-15px);
    }
  }
  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
  }
`;
